<script lang="ts" setup>
import { ref } from "vue";
import FormInput from "../form/formInput.vue";
import {
  AddressModelSchema,
  postalCodeSchema,
} from "../../utils/API/Cart/cartAPISchema";
import { CheckoutSteps } from "../../services/models/checkout.models";
import Icon from "../icon/icon.vue";
import { validationHelper } from "../../utils/validationHelpers";
import { USER_FEEDBACK, API_ERROR_MESSAGES } from "../../utils/API/APIErrors";
import { sentenceCase } from "../../../../helpers/checkoutHelpers";
import { useCustomerInformationStore } from "./stores/customerInformationStore";
import { useCheckoutStateStore } from "./stores/checkoutStateStore";
import * as CheckoutService from "../../services/checkout.service";

const props = defineProps<{
  isActive?: boolean;
}>();

const customerInformationStore = useCustomerInformationStore();
const checkoutStateStore = useCheckoutStateStore();
const retrievingCity = ref<boolean>(false);

const submitForm = () => {
  CheckoutService.goToCheckoutStep(CheckoutSteps.Delivery);
};

const handleBlur = (path: string) => {
  let validationResult = AddressModelSchema.safeParse(
    customerInformationStore.fields,
  );

  if (validationResult.success) {
    customerInformationStore.setError(path, []);
    return;
  }

  const pathRelatedErrors = validationResult.error.errors.filter(
    (error) => error.path.toString() === path,
  );

  const errorMessages = pathRelatedErrors.map((error) => error.message);
  customerInformationStore.setError(path, errorMessages);
};

const handlePostalPlaceBlur = async () => {
  let validationResult = postalCodeSchema.safeParse(
    customerInformationStore.fields?.postalCode,
  );

  if (!validationResult.success) {
    const errorMessages = validationResult.error.errors.map(
      (error) => error.message,
    );

    customerInformationStore.setError("postalCode", errorMessages);

    return;
  }

  const matchingCityResult = await CheckoutService.TryGetCityMatchingPostalCode(
    validationResult.data,
  );

  if (
    !matchingCityResult ||
    matchingCityResult === API_ERROR_MESSAGES.COULD_NOT_GET_CITY
  ) {
    return;
  }

  if (matchingCityResult === USER_FEEDBACK.UNKNOWN_POSTALCODE) {
    customerInformationStore.setError("postalCode", [
      USER_FEEDBACK.UNKNOWN_POSTALCODE,
    ]);

    retrievingCity.value = false;
    return;
  }

  customerInformationStore.setError("postalCode", []);
  customerInformationStore.setError("city", []);
};

/**
 * Handles the input of a postal code.
 *
 *
 * @param {string} postalCode - The postal code input.
 * @returns {Promise<void>} - A promise that resolves when the postal code input is handled.
 */
const handlePostalCodeInput = async (postalCode: string) => {
  customerInformationStore.setField("postalCode", postalCode);

  if (!validationHelper.isPostalCode(postalCode)) return;

  const matchingCityResult = await CheckoutService.TryGetCityMatchingPostalCode(postalCode);
  if (
    !matchingCityResult ||
    matchingCityResult === API_ERROR_MESSAGES.COULD_NOT_GET_CITY
  )
    return;

  if (matchingCityResult === USER_FEEDBACK.UNKNOWN_POSTALCODE) {
    customerInformationStore.setError("postalCode", [
      USER_FEEDBACK.UNKNOWN_POSTALCODE,
    ]);

    retrievingCity.value = false;
    return;
  }

  customerInformationStore.setField("city", sentenceCase(matchingCityResult));
  customerInformationStore.setError("postalCode", []);
  customerInformationStore.setError("city", []);
};


</script>

<template>
  <div>
    <div
      v-if="!props.isActive && customerInformationStore.fields"
      class="checkout-form__inactive"
    >
      <div>
        <div>
          <span>{{ customerInformationStore.fields?.firstName }}</span>
          <span>{{ customerInformationStore.fields?.lastName }}</span>
        </div>
        <div>
          <span>{{ customerInformationStore.fields?.address }},</span>
          <span>{{ customerInformationStore.fields?.postalCode }}</span>
          <span>{{ customerInformationStore.fields?.city }}</span>
        </div>
        <span>{{ customerInformationStore.fields?.email }},</span>
        <span>{{ customerInformationStore.fields?.phoneNumber }}</span>
      </div>
    </div>
    <form
      v-if="props.isActive"
      class="checkout-form"
      novalidate
      @submit.prevent="submitForm"
    >
      <fieldset class="checkout-form__fieldset--customerinformation">
        <legend>{{ $t("customerInformation") }}</legend>
        <div
          v-if="
            customerInformationStore.errorSummaryVisible && customerInformationStore.errorsExist
          "
          ref="customerInformationErrorsElement"
          tabindex="-1"
          class="checkout-form__error-summary"
        >
          <h3 v-if="customerInformationStore.errorsExist">
            {{ $t("errorsInForm") }}
          </h3>
          <ul
            role="alert"
            class="checkout-form__error-list"
            :aria-hidden="customerInformationStore.errorsExist"
          >
            <template
              v-for="(messages, path) in customerInformationStore.errors"
            >
              <li v-if="messages[0]" :key="path" class="checkout-form__error">
                {{ $t(path) }}: {{ messages[0] }}
              </li>
            </template>
          </ul>
        </div>
        <FormInput
          v-model="customerInformationStore.fields.email"
          input-class="checkout-form__input"
          :label="$t('email').toString()"
          :error="customerInformationStore.errors.email?.[0]"
          label-class="checkout-form__label"
          type="email"
          :required="true"
          :autofocus="true"
          autocomplete="email"
          @blur="() => handleBlur('email')"
        />
        <div class="checkout-form__row">
          <FormInput
            v-model="customerInformationStore.fields.firstName"
            :label="$t('firstName').toString()"
            :error="customerInformationStore.errors.firstName?.[0]"
            input-class="checkout-form__input"
            label-class="checkout-form__label"
            type="text"
            :required="true"
            autocomplete="given-name"
            @blur="() => handleBlur('firstName')"
          />
          <FormInput
            v-model="customerInformationStore.fields.lastName"
            :label="$t('lastName').toString()"
            :error="customerInformationStore.errors.lastName?.[0]"
            input-class="checkout-form__input"
            label-class="checkout-form__label"
            type="text"
            :required="true"
            autocomplete="family-name"
            @blur="() => handleBlur('lastName')"
          />
        </div>
        <FormInput
          v-model="customerInformationStore.fields.phoneNumber"
          :label="$t('phoneNumber').toString()"
          :error="customerInformationStore.errors.phoneNumber?.[0]"
          input-class="checkout-form__input"
          label-class="checkout-form__label"
          type="tel"
          :required="true"
          autocomplete="tel"
          @blur="() => handleBlur('phoneNumber')"
        />
        <FormInput
          v-model="customerInformationStore.fields.address"
          :label="$t('address').toString()"
          :error="customerInformationStore.errors.address?.[0]"
          input-class="checkout-form__input"
          label-class="checkout-form__label"
          type="text"
          :required="true"
          autocomplete="street-address"
          @blur="() => handleBlur('address')"
        />
        <div class="checkout-form__row">
          <FormInput
            v-model="customerInformationStore.fields.postalCode"
            :label="$t('postalCode').toString()"
            :error="customerInformationStore.errors.postalCode?.[0]"
            input-class="checkout-form__input"
            label-class="checkout-form__label"
            type="text"
            :required="true"
            autocomplete="postal-code"
            @input="($event) => handlePostalCodeInput($event.target.value)"
            @blur="() => handlePostalPlaceBlur()"
          />
          <FormInput
            v-model="customerInformationStore.fields.city"
            :label="$t('city').toString()"
            :error="customerInformationStore.errors.city?.[0]"
            input-class="checkout-form__input"
            label-class="checkout-form__label"
            type="text"
            :required="true"
            autocomplete="address-level2"
            :disabled="customerInformationStore.retrievingCity"
            @blur="() => handleBlur('city')"
          />
        </div>
        <button
          :disabled="checkoutStateStore.isLoading"
          class="btn btn--profile btn--checkout btn--smaller checkout-form__submit"
          type="submit"
        >
          <Icon
            v-if="checkoutStateStore.isLoading"
            type="spinner"
            size="tiny"
            class="icon--spinner"
          ></Icon>
          <span v-if="checkoutStateStore.isLoading">{{ $t("loading") }}</span>
          <span v-if="!checkoutStateStore.isLoading">{{ $t("continue") }}</span>
        </button>
      </fieldset>
    </form>
  </div>
</template>
