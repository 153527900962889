<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  formatWithoutRounding,
} from "../../mixins/productPrice.mixin";
import { Cart } from "../../utils/API/Cart/cartAPISchema";
import { Money } from "../../services/models/cart.models";
import { formatCurrency } from "../../utils/currencyHelpers";
import autoAnimate from "@formkit/auto-animate";
import ShoppingCartError from "../shoppingCart/shoppingCartError.vue";
import {
  amountNeededToQualifyForFreeShipping,
  shippingCostFormatted,
  qualifiesForFreeShipping,
} from "../../../../helpers/checkoutHelpers";

const props = defineProps<{
  cart?: Cart;
  headingClass?: string;
  showCheckoutLink?: boolean;
  checkoutAvailable?: boolean;
  error?: string;
}>();

const root = ref();
const totalList = ref();

const error = computed(() => {
  return props.error;
});

/**
 * Computes whether the shopping cart is eligible for free shipping.
 *
 * @returns {boolean} True if the shopping cart is eligible for free shipping, false otherwise.
 */
const freeShipping = computed(() => {
  if (!props.cart) return false;

  return qualifiesForFreeShipping(
    props.cart.freeShippingActive,
    props.cart.freeShippingLimit,
    props.cart.subTotal,
  );
});

/**
 * Computed property that returns the shipping call to action text.
 *
 * @returns {string} The shipping call to action text.
 */
const shippingCallToActionText = computed(() => {
  const remainingAmount = amountNeededToQualifyForFreeShipping(
    props.cart?.freeShippingActive,
    props.cart?.freeShippingLimit,
    props.cart?.subTotal,
  )?.amount;

  if (!remainingAmount) return "";

  return `Du er ${formatWithoutRounding(remainingAmount)} unna fri frakt`;
});

/**
 * Computed property that returns the shipping cost text.
 * If the cart is not available, a placeholder text is returned.
 *
 * @returns {string} The shipping cost text.
 */
const shippingCostText = computed(() => {
  if (!props.cart) return "Beregnes senere";

  return shippingCostFormatted(
    props.cart.freeShippingActive,
    props.cart.freeShippingLimit,
    props.cart.subTotal,
    props.cart.selectedShippingMethodPrice,
  );
});

onMounted(() => {
  autoAnimate(root.value);
  autoAnimate(totalList.value);
});
</script>

<template>
  <div ref="root" class="checkout-summary checkout-summary--checkout">
    <h2 class="checkout-summary__heading sr-only">{{ $t("summary") }}</h2>
    <ShoppingCartError :error="error" type="global" />
    <div>
      <div
        ref="totalList"
        class="checkout-summary__group checkout-summary__group--checkout"
      >
        <div
          v-if="props.cart?.discountTotal?.amount"
          class="checkout-summary__entry checkout-summary__entry"
        >
          <div>{{ $t("discountTotal") }}</div>
          <div>
            <span class="checkout-summary__discount"
              >-
              {{
                props.cart.discountTotal &&
                formatCurrency(props.cart.discountTotal.currency)
              }}
              {{ formatWithoutRounding(props.cart.discountTotal.amount) }}</span
            >
          </div>
        </div>
        <div
          v-if="props.cart?.total?.amount"
          class="checkout-summary__entry checkout-summary__entry"
        >
          <div>{{ $t("shipping") }}</div>
          <div>
            <span>{{ shippingCostText }}</span>
          </div>
        </div>
        <div
          v-if="props.cart && !freeShipping"
          class="checkout-summary__entry checkout-summary__entry--gray"
        >
          <div class="checkout-summary__shipping-tip">
            {{ shippingCallToActionText }}
          </div>
        </div>
      </div>
      <h4 class="checkout-summary__total">
        <span>{{ $t("total") }}: </span>
        <span
          >{{ props.cart?.total && formatCurrency(props.cart.total.currency) }}
          {{
            props.cart?.total && formatWithoutRounding(props.cart.total.amount)
          }}</span
        >
      </h4>
    </div>
  </div>
</template>
