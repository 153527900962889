<template>
  <form @submit.prevent="submitForm">
    <div class="product__size">
      <component :is="sizePickerComponent" v-bind="sizePickerProps"></component>
    </div>

    <!-- Display modelSizeInformation if it's not an empty string -->
    <div v-if="modelSizeInformation" class="product__model-information">
      {{ modelSizeInformation }}
    </div>

    <div v-if="isProductInStock" class="_js-add-to-cart-container product__add">
      <input type="hidden" value="1" :name="skuParameterCode" />
      <button
        class="btn btn--profile btn--inset u-display-block btn--add-to-cart"
        type="submit"
      >
        {{ addToBasket }}
      </button>
    </div>
    <div v-else class="product__inventory product__inventory--empty-stock">
      <div v-if="props.isNews" class="product__not-available">
        {{ soldoutNews }}
      </div>
      <div v-else>
        {{
          "@Html.DisplayFor(m => m.SiteSettings.ProductOnlyAvailableInStore)"
        }}
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";
import sizePickerSuit from "./sizePickerSuit.vue";
import sizePickerPants from "./sizePickerPants.vue";
import sizePickerRegular from "./sizePickerRegular.vue";
import { useAddToCartStore } from "./stores/addToCartStore";
import { LogErrorResponse } from "../../utils/API/Logging/LoggingAPI";
import { displayCartStatus } from "../../../../utils";
import { vueComponentToggler } from "../../../../global/modules/toggler_wp";
import { vueInventory } from "../../../../global/modules/inventory_wp";

const addToCartStore = useAddToCartStore();
const CONTROLLER_URL = "/Cart/AddToCart";

const props = defineProps<{
  sizePickerType: string; // 'suit', 'pants', or 'regular'
  sizesMatrixJson: string;
  modelSizeInformation: string;
  chooseSize: string;
  chooseWidth: string;
  chooseLength: string;
  sizeGuide: string;
  isSizeGuide: string;
  selectSizeMessage: string;
  selectWidthMessage: string;
  selectLengthMessage: string;
  skuParameterCode: string;
  sizesListJson?: string; // Only needed for sizePickerRegular
  addToBasket: string;
  isProductInStock: string;
  isNews: string;
  soldoutNews: string;
  inventoryNotificationTrigger: string;
}>();

// Computed property to determine which component to render
const sizePickerComponent = computed(() => {
  switch (props.sizePickerType) {
    case "suit":
      return sizePickerSuit;
    case "pants":
      return sizePickerPants;
    case "regular":
      return sizePickerRegular;
    default:
      return null; // Handle cases where no valid type is provided
  }
});

// Computed property deciding whether to render sizePickerSuit, sizePickerPants, or sizePickerRegular
const sizePickerProps = computed(() => {
  const commonProps = {
    sizeGuide: props.sizeGuide,
    isSizeGuide: props.isSizeGuide === "True",
    skuParameterCode: props.skuParameterCode,
    modelSizeInformation: props.modelSizeInformation,
    inventoryNotificationTrigger: props.inventoryNotificationTrigger,
  };

  if (props.sizePickerType === "suit") {
    return {
      ...commonProps,
      sizesMatrixJson: props.sizesMatrixJson,
      chooseSize: props.chooseSize,
      chooseWidth: props.chooseWidth,
      chooseLength: props.chooseLength,
      selectSizeMessage: props.selectSizeMessage,
      selectWidthMessage: props.selectWidthMessage,
      selectLengthMessage: props.selectLengthMessage,
    };
  } else if (props.sizePickerType === "pants") {
    return {
      ...commonProps,
      sizesMatrixJson: props.sizesMatrixJson,
      chooseWidth: props.chooseWidth,
      chooseLength: props.chooseLength,
      selectWidthMessage: props.selectWidthMessage,
      selectLengthMessage: props.selectLengthMessage,
    };
  } else if (props.sizePickerType === "regular") {
    return {
      ...commonProps,
      sizesListJson: props.sizesListJson,
      chooseSize: props.chooseSize,
      selectSizeMessage: props.selectSizeMessage,
    };
  } else {
    return {};
  }
});

const validateRegularSizeSelection = () => {
  if (!addToCartStore.sizeSelected) {
    addToCartStore.displaySizeNotSelectedError = true;
    return false;
  } else {
    addToCartStore.displaySizeNotSelectedError = false;
    return true;
  }
};

const validatePantsSizeSelection = () => {
  if (addToCartStore.widthSelected && addToCartStore.lengthSelected) {
    addToCartStore.displayWidthNotSelectedError = false;
    addToCartStore.displayLengthNotSelectedError = false;
    return true;
  } else {
    if (!addToCartStore.widthSelected) {
      addToCartStore.displayWidthNotSelectedError = true;
    } else if (!addToCartStore.lengthSelected) {
      addToCartStore.displayLengthNotSelectedError = true;
    }
    if (!addToCartStore.lengthSelected) {
      addToCartStore.displayLengthNotSelectedError = true;
    } else if (!addToCartStore.widthSelected) {
      addToCartStore.displayWidthNotSelectedError = true;
    }
    return false;
  }
};

const validateSuitSizeSelection = () => {
  if (
    addToCartStore.sizeSelected &&
    addToCartStore.widthSelected &&
    addToCartStore.lengthSelected
  ) {
    addToCartStore.displaySizeNotSelectedError = false;
    addToCartStore.displayWidthNotSelectedError = false;
    addToCartStore.displayLengthNotSelectedError = false;
    return true;
  } else {
    if (!addToCartStore.sizeSelected) {
      addToCartStore.displaySizeNotSelectedError = true;
    } else if (addToCartStore.sizeSelected) {
      addToCartStore.displaySizeNotSelectedError = false;
    }
    if (!addToCartStore.widthSelected) {
      addToCartStore.displayWidthNotSelectedError = true;
    } else if (addToCartStore.widthSelected) {
      addToCartStore.displayWidthNotSelectedError = false;
    }
    if (!addToCartStore.lengthSelected) {
      addToCartStore.displayLengthNotSelectedError = true;
    } else if (addToCartStore.lengthSelected) {
      addToCartStore.displayLengthNotSelectedError = false;
    }
    return false;
  }
};

// Validate size selection based on sizePickerType
const validateSizeSelection = () => {
  switch (props.sizePickerType) {
    case "suit":
      if (validateSuitSizeSelection()) return true;
      break;
    case "pants":
      if (validatePantsSizeSelection()) return true;
      break;
    case "regular":
      if (validateRegularSizeSelection()) return true;
    default:
      return false;
  }
};

const gtmPush = (gtmString: string) => {
  // eslint-disable-next-line
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(gtmString);
};

// Failure toast animation logic
function failureToast() {
  const toastMessage = document.querySelector(".toast-message");
  const toast = document.querySelector(".toast");

  if (toastMessage && toast) {
    toastMessage.classList.remove("is-hidden");
    toast.classList.add("is-animating");

    setTimeout(() => {
      toast.classList.remove("is-animating");
      toastMessage.setAttribute("aria-hidden", "true");
    }, 3000);

    setTimeout(() => {
      toastMessage.classList.add("is-hidden");
    }, 4000);
  }
}

// Helper function to handle the failure toast logic
function showFailureToast(message: string) {
  const toastMessage = document.querySelector(".toast-message");
  const toast = document.querySelector(".toast");

  if (toastMessage && toast) {
    toastMessage.textContent = message;
    toastMessage.setAttribute("aria-hidden", "false");
    toast.classList.add("error");

    // Animate toast
    failureToast();

    // Reset the toast after 4 seconds
    setTimeout(() => {
      toastMessage.classList.remove("error");
    }, 4000);
  }
}

async function addToCart(): Promise<void> {
  const url = `${CONTROLLER_URL}`;
  const formData = {
    c: addToCartStore.skuCode,
    q: 1,
  };
  const body = JSON.stringify(formData);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });

  if (!response.ok) {
    await LogErrorResponse(url, response, body);
    showFailureToast("Det skjedde en feil. Prøv igjen senere");
    return Promise.reject("Det skjedde en feil. Prøv igjen senere");
  }

  displayCartStatus();
  gtmPush(JSON.stringify(response));
  addToCartStore.incrementCartItemCount();
}

const submitForm = () => {
  console.log("Legg i handlekurv clicked");
  if (!validateSizeSelection()) {
    console.log("not validated");
    return;
  }

  addToCart();
};

onMounted(() => {
  vueComponentToggler();
  vueInventory();
});
</script>
