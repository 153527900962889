import { defineComponent } from "vue";

import { AxiosResponse } from "axios";
import FavoriteService from "../services/favorites.service";
import { updateFavorites } from "../../modules/favorite_wp";

export default defineComponent({
    data() {
        return {
            favorites: null as Array<string> | null,
            isLoadingFavorites: false,
        };
    },
    methods: {
        /**
         * Get favorites
         */
        getFavorites(): Promise<any> {
            this.isLoadingFavorites = true;

            return FavoriteService.getFavorites()
                .then((response: AxiosResponse<Array<number | string>>) => {
                    this.favorites = response.data.map((i) => String(i));

                    this.isLoadingFavorites = false;

                    updateFavorites();
                })
                .catch(() => {
                    // Fail silently...
                    this.favorites = null;
                });
        },
        /**
         * Toggle favorite
         */
        toggleFavorite(productKey: string) {
            this.isLoadingFavorites = true;

            FavoriteService.toggleFavorite(productKey)
                .then(this.getFavorites)
                .catch(this.getFavorites);
        },
    },
});

export function getFavorites() {
    return FavoriteService.getFavorites()
        .then((response: AxiosResponse<Array<number | string>>) => {
            const favoriteCartAmount = document.querySelector(
                "._js-minicart-amount",
            );
            if (favoriteCartAmount) {
                favoriteCartAmount.innerHTML = response.data.length
                    ? response.data.length.toString()
                    : "";
            }
            return response;
        })
        .catch(() => {
            // Fail silently...
            return null;
        });
}

/**
 * Toggle favorite
 */
export const toggleFavorite = (productKey: string) => {
    return FavoriteService.toggleFavorite(productKey)
        .then(getFavorites)
        .catch(getFavorites);
};
