import { Product } from "../services/search.service";
import { getCustomApptusValue } from "../utils/search.utils";

export function computePromotion(product: Product):
    | {
          className: string;
          text: string;
      }
    | undefined {
    const promotionLabel = getCustomApptusValue(product, "Promotion");
    if (promotionLabel) {
        return {
            className: "label--amountdiscount", // TODO
            text: promotionLabel,
        };
    } else {
        return undefined;
    }
}
export function computeBadge(product: Product):
    | {
          className: string;
          text: string;
      }
    | undefined {
    const primaryBadges = product.badges?.primary;
    const secondaryBadges = product.badges?.secondary;

    const primaryBadge = primaryBadges;
    const secondaryBadge = secondaryBadges;

    const badge = primaryBadge || secondaryBadge;

    if (badge) {
        return {
            className: "label--amountdiscount", // TODO
            text: badge.label,
        };
    }
}
