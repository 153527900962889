<script lang="ts" setup>
import {
  PaymentMethodViewModel,
  PersonIdentifierSchema,
  SelectListItem,
} from "../../utils/API/Payment/paymentAPISchema";
import {
  logoNameForPaymentMethod,
  paymentClass,
  AfterpayMethods,
  hasAfterpayInstallmentInfo,
} from "../../../../helpers/checkoutHelpers";
import ShoppingCartError from "../shoppingCart/shoppingCartError.vue";
import FormInput from "../form/formInput.vue";

import Icon from "../icon/icon.vue";
import { ref, onMounted } from "vue";
import { CheckoutSteps } from "../../services/models/checkout.models";
import Combobox from "../../components/combobox/Combobox.vue";
import AfterpayAccountInfo from "./afterpayAccountInfo.vue";
import FinalizePaymentError from "./finalizePaymentError.vue";
import AfterpayInstallment from "./afterpayInstallment.vue";
import { ComboboxOptionModel } from "../../services/models/combobox.models";
import autoAnimate from "@formkit/auto-animate";
import { usePaymentInformationStore } from "./stores/paymentInformationStore";
import { useCheckoutStateStore } from "./stores/checkoutStateStore";
import * as CheckoutService from "../../services/checkout.service";

const props = defineProps<{
  isActive: boolean;
}>();

const paymentInformationStore = usePaymentInformationStore();
const checkoutStateStore = useCheckoutStateStore();
const submitContainerElement = ref<HTMLElement>();
const finalizePaymentErrorElement = ref<HTMLDialogElement>();

const submitForm = (): void => {
  CheckoutService.goToCheckoutStep(CheckoutSteps.FinalizeOrder);
};

const handleSetPaymentOption = (
  paymentMethod: PaymentMethodViewModel,
): void => {
  if (!paymentMethod.afterPayViewModel?.installmentInfo) {
    paymentInformationStore.setSelectedPaymentMethod(paymentMethod);
    return;
  }

  paymentInformationStore.setSelectedPaymentMethod(paymentMethod);
  paymentInformationStore.setSelectedAfterPayInstallmentOption(
    AfterpayMethods.Account,
  );
  paymentInformationStore.setSelectedAfterPayInstallmentPlan(
    Object.values(
      paymentMethod?.afterPayViewModel?.installmentInfo?.installmentInfoItems,
    )?.[0],
  );
};

const handlePersonIdentifierInput = (personIdentifierValue: string): void => {
  paymentInformationStore.setPersonIdentifier(personIdentifierValue);
};

/**
 * Handles the blur event for the person identifier input field.
 * Validates that the person identifier is 11 characters long using zod.
 */
const handlePersonIdentifierBlur = (path: string): void => {
  const validationResult = PersonIdentifierSchema.safeParse(
    paymentInformationStore.personIdentifier,
  );

  if (validationResult.success) {
    paymentInformationStore.setError(path, []);
    return;
  }

  const pathRelatedErrors = validationResult.error.errors.filter(
    (error) => error.path.toString() === path || [],
  );

  const errorMessages = pathRelatedErrors.map((error) => error.message);
  paymentInformationStore.setError(path, errorMessages);
};

const handleSetSelectedAfterpayMethod = (method: string): void => {
  paymentInformationStore.setSelectedAfterPayInstallmentOption(method);
};

/**
 * Generates an array of combobox options based on the provided payment method.
 * If the payment method does not have any installment info, an empty array is returned.
 *
 * @param {PaymentMethodViewModel} paymentMethod - The payment method object.
 * @returns {Array<ComboboxOptionModel>} - An array of combobox options.
 */
const comboboxOptions = (
  paymentMethod: PaymentMethodViewModel,
): Array<ComboboxOptionModel> => {
  return (
    paymentMethod.afterPayViewModel?.installmentInfo?.installmentListItems?.map(
      (listItem: SelectListItem) => ({
        heading: listItem.text || "",
        id: listItem.value,
      }),
    ) || []
  );
};

const setSelectedAfterpayInstallmentPlanByValue = (value: string): void => {
  paymentInformationStore.setSelectedAfterpayInstallmentPlanByValue(value);
};

onMounted(() => {
  if (submitContainerElement.value) {
    autoAnimate(submitContainerElement.value);
  }
});
</script>

<template>
  <div>
    <div
      v-if="!props.isActive && paymentInformationStore.selectedPaymentMethod"
      class="checkout-form__inactive"
    >
      <div v-if="paymentInformationStore.selectedPaymentMethod">
        <span>{{ paymentInformationStore.selectedPaymentMethod.name }}</span>
      </div>
    </div>
    <form
      v-if="props.isActive && paymentInformationStore.paymentMethods"
      class="checkout-form checkout-delivery"
      novalidate
      @submit.prevent="submitForm"
    >
      <fieldset>
        <legend>{{ $t("paymentMethods") }}</legend>
        <div
          v-for="paymentMethod in paymentInformationStore.paymentMethods"
          :key="paymentMethod.id"
          class="checkout-form__radio"
        >
          <label class="checkout-form__label" :for="paymentMethod.id">
            <input
              :id="paymentMethod.id"
              type="radio"
              name="delivery-method"
              :checked="
                paymentMethod.systemKeyword ===
                paymentInformationStore.selectedPaymentMethod?.systemKeyword
              "
              @click="handleSetPaymentOption(paymentMethod)"
            />
            <div class="checkout-form__radio-heading">
              {{ paymentMethod.name }}
            </div>
            <div
              class="checkout-form__radio-content checkout-payment__installment-selection"
            >
              <div class="checkout-form__radio-description">
                <div>
                  <span v-if="paymentMethod.description">
                    {{ paymentMethod.description }}
                  </span>
                </div>
                <Icon
                  v-if="logoNameForPaymentMethod(paymentMethod.imageId)"
                  :type="logoNameForPaymentMethod(paymentMethod.imageId)"
                  :title="`${paymentMethod.name} logo`"
                  size="delivery-logo"
                  class="checkout-delivery-icon checkout-delivery-icon--porterbuddy"
                />
              </div>
              <fieldset
                v-if="
                  paymentMethod.systemKeyword ===
                    paymentInformationStore.selectedPaymentMethod
                      ?.systemKeyword &&
                  paymentInformationStore.selectedPaymentMethod
                    ?.isAfterPayInstallment
                "
                class=""
              >
                <legend>{{ $t("selectAfterpayMethod") }}</legend>
                <div
                  class="checkout-form__radio"
                  @click="
                    handleSetSelectedAfterpayMethod(AfterpayMethods.Account)
                  "
                >
                  <label
                    class="checkout-form__label"
                    :for="AfterpayMethods.Account"
                  >
                    <input
                      :id="AfterpayMethods.Account"
                      type="radio"
                      name="afterpay-method"
                      :checked="
                        paymentInformationStore.selectedAfterPayInstallmentOption ===
                        AfterpayMethods.Account
                      "
                      :value="AfterpayMethods.Account"
                    />
                    <div class="checkout-form__radio-heading">
                      {{ $t("afterpayAccountlabel") }}
                    </div>
                    <div class="checkout-form__radio-content">
                      <AfterpayAccountInfo
                        v-if="
                          paymentInformationStore.selectedPaymentMethod
                            ?.afterPayViewModel?.accountInfo
                        "
                        class="checkout-payment__afterpay-account-info"
                        :account-info="
                          paymentInformationStore.selectedPaymentMethod
                            ?.afterPayViewModel?.accountInfo
                        "
                      >
                      </AfterpayAccountInfo>
                    </div>
                  </label>
                </div>
                <div
                  v-if="
                    hasAfterpayInstallmentInfo(
                      paymentInformationStore.selectedPaymentMethod,
                    )
                  "
                  class="checkout-form__radio"
                  @click="
                    handleSetSelectedAfterpayMethod(AfterpayMethods.Installment)
                  "
                >
                  <label
                    class="checkout-form__label"
                    :for="AfterpayMethods.Installment"
                  >
                    <input
                      :id="AfterpayMethods.Installment"
                      type="radio"
                      name="afterpay-method"
                      :value="AfterpayMethods.Installment"
                      :checked="
                        paymentInformationStore.selectedAfterPayInstallmentOption ===
                        AfterpayMethods.Installment
                      "
                    />
                    <div class="checkout-form__radio-heading">
                      {{ $t("afterpayInstallmentlabel") }}
                    </div>
                    <div class="checkout-form__radio-content">
                      <Combobox
                        @update:modelValue="
                          setSelectedAfterpayInstallmentPlanByValue
                        "
                        heading="Velg nedbetalingsløsning"
                        :hide-heading="true"
                        :options="
                          comboboxOptions(
                            paymentInformationStore.selectedPaymentMethod,
                          )
                        "
                        :selected-id="
                          paymentInformationStore.selectedAfterPayInstallmentPlanId
                        "
                      ></Combobox>
                      <AfterpayInstallment
                        class="checkout-payment__afterpay-installment-info"
                        :installment="
                          paymentInformationStore.selectedAfterPayInstallmentPlan
                        "
                      ></AfterpayInstallment>
                    </div>
                  </label>
                </div>
              </fieldset>
              <div
                v-if="
                  paymentMethod.requirePersonIdentifier &&
                  paymentMethod.systemKeyword ===
                    paymentInformationStore.selectedPaymentMethod?.systemKeyword
                "
                :class="paymentClass(paymentMethod.id)"
              >
                <FormInput
                  :value="paymentInformationStore.personIdentifier"
                  label="Fødselsnummer"
                  label-class="checkout-payment__person-identifier"
                  type="tel"
                  :required="true"
                  autocomplete="off"
                  pattern="\d{11}"
                  :autofocus="true"
                  :error="paymentInformationStore.errors.personIdentifier[0]"
                  :display-required-error="true"
                  @input="(value) => handlePersonIdentifierInput(value)"
                  @blur="() => handlePersonIdentifierBlur('personIdentifier')"
                />
                <p
                  v-if="paymentMethod.disclaimer"
                  class="checkout-payment__disclaimer"
                  v-html="paymentMethod.disclaimer"
                ></p>
              </div>
            </div>
          </label>
        </div>
      </fieldset>
      <div class="checkout-payment__submit" ref="submitContainerElement">
        <ShoppingCartError
          v-if="paymentInformationStore.errors.finalizeOrder.length"
          ref="submitErrrorElement"
          class="checkout-payment__submit-error"
          type="global"
          :error-is-html="true"
          :error="paymentInformationStore.errors.finalizeOrder[0]"
        />
        <button
          :disabled="checkoutStateStore.isLoading"
          class="btn btn--profile btn--checkout"
          type="submit"
        >
          <Icon
            v-if="checkoutStateStore.isLoading"
            type="spinner"
            size="tiny"
            class="icon--spinner"
          ></Icon>
          <span v-if="checkoutStateStore.isLoading">{{ $t("loading") }}</span>
          <span v-if="!checkoutStateStore.isLoading">{{
            $t("finalizeOrder")
          }}</span>
        </button>
        <FinalizePaymentError
          ref="finalizePaymentErrorElement"
          :visible="paymentInformationStore.showCartContentChangedPaymentError"
          :error-details="
            paymentInformationStore.cartContentChangedPaymentContent
          "
        />
      </div>
    </form>
  </div>
</template>
