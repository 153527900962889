export default class Quicksearch {
    constructor(element) {
        // Quicksearch
        // TODO: Toggle aria on AND off
        // Close when clicking outside search area
        element.addEventListener('click', function () {
            document.querySelector('._js-quicksearch-input')?.focus();
        });

    }
}

