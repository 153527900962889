<template>
  <div>
    <!-- Preview bar -->
    <PreviewBar v-if="isPreview" @clickExit="clickExitPreview()" />
    <!-- Search page -->
    <div class="l bg-neutral-lightest">
      <div class="l__content">
        <h1 class="sr-only">
          {{ displayName }}
        </h1>
        <div class="layout-searchpage__top">
          <div class="layout-searchpage__search-form">
            <!-- Quick search -->
            <QuickSearch
              ref="quickSearch"
              class-name="search-form quicksearch--on-page"
              :absolute-panel="true"
              :search-page-url="baseUrlPath"
              :placeholder="$tc('searchPlaceholder')"
              :reset-on-search="true"
              :disabled="isLoading"
              :is-results-hidden="isQuickSearchResultsHidden"
              @input="showQuickSearch($event)"
              @inputFocus="showQuickSearch($event.target.value)"
            />
          </div>
          <!-- You searched for -->
          <p
            v-if="searchQuery"
            class="h2 u-text-center u-trailer u-leader-half"
          >
            {{ $t("youSearchedFor") }} "{{ searchQuery }}"
          </p>
          <!-- Categories -->
          <fieldset
            v-if="searchQuery"
            class="layout-searchpage__search-category"
          >
            <legend class="sr-only">
              {{ $t("categories") }}
            </legend>
            <label v-if="displayProductsRadio" class="radio u-padded-quart">
              <input
                v-model="searchCategory"
                type="radio"
                name="search-category"
                :value="SearchPageRoutes.Products"
                @change="changeCategory($event)"
              />
              <span>{{ $t("products") }} ({{ nProducts }})</span>
            </label>
            <label v-if="nArticles" class="radio u-padded-quart">
              <input
                v-model="searchCategory"
                type="radio"
                name="search-category"
                :value="SearchPageRoutes.Articles"
                @change="changeCategory($event)"
              />
              <span>{{ $t("articles") }} ({{ nArticles }})</span>
            </label>
            <label v-if="nStores" class="radio u-padded-quart">
              <input
                v-model="searchCategory"
                type="radio"
                name="search-category"
                :value="SearchPageRoutes.Stores"
                @change="changeCategory($event)"
              />
              <span>{{ $t("stores") }} ({{ nStores }})</span>
            </label>
          </fieldset>
        </div>
      </div>
    </div>
    <!-- Hits -->
    <div class="layout-searchpage__results">
      <!-- Message -->
      <div
        v-if="searchResult && searchResult.message"
        class="layout-searchpage__nohits"
        role="alert"
      >
        {{ searchResult.message }}
      </div>
      <!-- No hits -->
      <div
        v-if="
          searchResult &&
          ((searchResult.primaryList &&
            searchResult.primaryList.productGroups &&
            searchResult.primaryList.productGroups.length === 0) ||
            (searchResult.autoCorrect &&
              searchResult.autoCorrect.originalTotalHits === 0)) &&
          searchCategory === SearchPageRoutes.Products
        "
        class="layout-searchpage__nohits"
        role="alert"
      >
        {{ $t("noHits") }}
      </div>
      <!-- Auto correct -->
      <div
        v-if="
          searchResult &&
          searchResult.autoCorrect &&
          searchCategory === SearchPageRoutes.Products
        "
      >
        <div class="layout-searchpage__hint">
          <strong>{{ $t("autoCorrect") }}</strong>
          <u>{{ searchResult.autoCorrect.q }}</u>
        </div>
      </div>
      <!-- Loader -->
      <div v-if="isLoading" class="loader-wrapper">
        <div class="loader" />
      </div>
      <!-- TODO: Error -->
      <div v-if="searchError" style="color: red">
        {{ searchError }}
      </div>
      <!-- Router view -->
      <template v-if="searchResult">
        <router-view
          v-bind="searchResult"
          :searchpage="true"
          :is-user-authenticated="isUserAuthenticated"
          :is-favorite-form-hidden="isFavoriteFormHidden"
          :there-are-more-pages="thereAreMorePages"
          :favorites="favorites"
          :is-loading-favorites="isLoadingFavorites"
          :use-product-gtm-tracking="true"
          :user-id="userId"
          @upsertFacet="upsertFacet($event)"
          @useFilter="applyFilter()"
          @nextPage="nextPage()"
          @toggleFavorite="clickFavorite($event)"
          @updateSort="updateSort($event)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios, { CancelTokenSource } from "axios";
import { SearchPageRoutes } from "./searchPage.router";
import { getFavorites, toggleFavorite } from "../../mixins/favorites";
import { routerReplace, routerPush } from "../../mixins/routing";
import SearchService, {
  SearchResult,
  CONTENT_LIST_ARTICLES,
  CONTENT_LIST_STORES,
  ContentList,
  Facet,
} from "../../services/search.service";
import PreviewBar from "../../components/previewBar/previewBar.vue";
import QuickSearch from "../../components/quickSearch/quickSearch.vue";

const FACET_PREFIX = "filter.";
const FACET_DELIMITER = ",";
const CANCEL_SEARCH_REQUEST = "CancelSearchRequest";
const INITIAL_SEARCH = true;

//Dataset means all props are strings for now
const props = defineProps<{
  baseUrlPath: string;
  displayName: string;
  userAuthenticated: string;
  favoriteFormHidden: string;
  pageSize: string;
  userId: string;
}>();

const isLoading = ref(false);
const searchQuery = ref("");
const page = ref(0);
const searchCategory = ref(SearchPageRoutes.Products);
const searchFacets = ref({});
const searchResult = ref(null as SearchResult | null);
const sort = ref(null as string | null);
const cancelTokenSource = ref(null as CancelTokenSource | null);
const searchError = ref("");
const isQuickSearchResultsHidden = ref(true);
const preview = ref(undefined as string | undefined);
const isLoadingFavorites = ref(false);
const favorites = ref(null as Array<string> | null);

// Get routes
const route = useRoute();
const router = useRouter();

onMounted(() => {
  if (isUserAuthenticated.value) {
    isLoadingFavorites.value = true;
    getFavorites()
      .then((response) => {
        favorites.value = response?.data.map((i) => String(i)) || null;
      })
      .finally(() => (isLoadingFavorites.value = false));
  }
});

const nProducts = computed((): number | undefined => {
  if (!searchResult.value || !searchResult.value?.primaryList) {
    return;
  }
  return searchResult.value.primaryList.totalHits;
});
const displayProductsRadio = computed((): boolean => {
  if (!searchResult.value) {
    return false;
  }
  if (searchFacets.value) {
    if (Object.keys(searchFacets.value).length > 0) {
      // Always show radio button if there are filters
      return true;
    }
  }
  if (!nArticles.value && !nStores.value) {
    return false;
  }
  if (!nProducts.value) {
    return false;
  }

  return true;
});
const nArticles = computed((): number | undefined => {
  const contentList = getContentList(searchResult.value, CONTENT_LIST_ARTICLES);

  return contentList && contentList.totalHits;
});
const nStores = computed((): number | undefined => {
  const contentList = getContentList(searchResult.value, CONTENT_LIST_STORES);

  return contentList && contentList.totalHits;
});
const pageSizeNumber = computed((): number => {
  return props.pageSize ? Number(props.pageSize) : 12;
});
const thereAreMorePages = computed((): boolean => {
  if (!searchResult.value || !searchResult.value.primaryList) {
    return false;
  }

  const limit = (page.value + 1) * pageSizeNumber.value;

  return searchResult.value.primaryList.totalHits > limit;
});
const isFavoriteFormHidden = computed((): boolean => {
  return props.favoriteFormHidden === "True";
});
const isUserAuthenticated = computed((): boolean => {
  return props.userAuthenticated === "True";
});
const isPreview = computed((): boolean => {
  return preview.value === "true";
});

watch(
  [() => route.fullPath, () => route.query, () => route.name],
  ([toPath, toQuery, toName], [fromPath, fromQuery, fromName]) => {
    /**
     * Initial navigation: Trigger search
     */
    if (!fromPath) {
      search(INITIAL_SEARCH);

      return;
    }
    /**
     * We don't want to trigger a search from just changing category (products/articles/stores)
     */
    if (fromQuery?.q === toQuery.q && fromName !== toName) {
      return;
    }
    /**
     * We don't want to trigger a search again after adding autoCorrect to the
     * browser URL. If "q" is equal to previous route, yet "ac" is defined
     * and changed: Prevent search.
     */
    if (
      fromQuery?.q === toQuery.q &&
      toQuery.ac &&
      fromQuery?.ac !== toQuery.ac
    ) {
      return;
    }
    /**
     * Route has changed: Trigger search
     */
    if (toPath !== fromPath) {
      search();
    }
  },
  { immediate: true },
);

/**
 * Show quick search
 */
function showQuickSearch(currentValue: string) {
  if (!currentValue) {
    isQuickSearchResultsHidden.value = true;
  } else {
    isQuickSearchResultsHidden.value = false;
  }
}

/**function
 * Search
 */
function search(isInitialSearch = false) {
  searchQuery.value = getSearchQueryFromRoute(route);
  searchCategory.value = getSearchCategoryFromRoute(route);
  searchFacets.value = getSearchFacetsFromRoute(route);
  sort.value = getSortFromRoute(route);
  page.value = getPageFromRoute(route);
  preview.value = getPreviewFromRoute(route);

  if (cancelTokenSource.value) {
    // Cancel previous request
    cancelTokenSource.value.cancel(CANCEL_SEARCH_REQUEST);

    cancelTokenSource.value = null;
  }

  if (!searchQuery.value) {
    searchResult.value = null;

    return;
  }

  isLoading.value = true;

  const localCancelTokenSource = (cancelTokenSource.value =
    axios.CancelToken.source());

  const searchPromise = SearchService.search(
    localCancelTokenSource,
    searchQuery.value,
    searchFacets.value,
    sort.value,
    pageSizeNumber.value,
    page.value,
    preview.value,
  );

  searchPromise
    .then((result) => {
      searchResult.value = result.data;

      if (searchResult.value && searchResult.value.autoCorrect) {
        /**
         * If search results contain autoCorrect, we want to store it in the browser
         * URL. This is because we want to actually search for the autoCorrected
         * term when filtering on facets. See getSearchQueryFromRoute().
         */
        routerReplace(
          {
            name: route.name,
            query: {
              ...route.query,
              ac: searchResult.value.autoCorrect.q,
            },
          },
          router,
        );
      }

      const defaultRouteName = isInitialSearch
        ? route.name
        : getDefaultRouteNameFromSearchResult(
            searchFacets.value,
            searchResult.value,
          );

      if (defaultRouteName !== route.name) {
        routerReplace(
          {
            name: defaultRouteName,
            query: {
              ...route.query,
            },
          },
          router,
        );
      }

      isLoading.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
      searchResult.value = null;

      if (error.message !== CANCEL_SEARCH_REQUEST) {
        console.error(error);
      }
    });
}

function clickFavorite(productKey: string) {
  isLoadingFavorites.value = true;
  toggleFavorite(productKey)
    .then((response) => {
      favorites.value = response?.data.map((i) => String(i)) || null;
    })
    .finally(() => (isLoadingFavorites.value = false));
}

/**
 * Change category
 */
function changeCategory(e: Event) {
  routerPush(
    {
      name: (<HTMLInputElement>e.target).value,
      query: route.query,
    },
    router,
  );
}
/**
 * Upsert facet
 */
function upsertFacet({
  facet,
  selectedValues,
  useFilter = false,
}: {
  facet: Facet;
  selectedValues: Array<string>;
  useFilter: boolean;
}) {
  searchFacets.value[facet.id] = selectedValues;

  if (useFilter) {
    applyFilter();
  }
}
/**
 * Update sort
 */
function updateSort(newSort: string): void {
  sort.value = newSort;
}
/**
 * Use filter
 */
function applyFilter() {
  const currentRouterQuery = route.query;

  const newRouterQuery = Object.assign({}, currentRouterQuery);

  Object.keys(searchFacets.value).forEach((facetId) => {
    const selectedValues = searchFacets.value[facetId];

    newRouterQuery[`${FACET_PREFIX}${facetId}`] =
      selectedValues.join(FACET_DELIMITER);

    if (selectedValues.length === 0) {
      delete newRouterQuery[`${FACET_PREFIX}${facetId}`];
    }
  });

  delete newRouterQuery["page"];

  if (sort.value) {
    newRouterQuery.sort = sort.value;
  } else {
    delete newRouterQuery.sort;
  }

  routerPush(
    {
      name: route.name || SearchPageRoutes.Products,
      query: newRouterQuery,
    },
    router,
  );
}
/**
 * Next page
 */
function nextPage() {
  const page = getPageFromRoute(route);

  routerPush(
    {
      name: route.name,
      query: {
        ...route.query,
        page: page + 1,
      },
    },
    router,
  );
}
/**
 * Click exit preview
 */
function clickExitPreview() {
  routerPush(
    {
      name: route.name,
      query: {
        q: route.query.q,
      },
    },
    router,
  );
}

function getSearchQueryFromRoute($route) {
  if ($route.query && $route.query.ac) {
    return $route.query.ac as string;
  }

  return (($route.query && $route.query.q) as string) || "";
}

function getSearchCategoryFromRoute($route) {
  return ($route.name as SearchPageRoutes) || SearchPageRoutes.Products;
}

function getSearchFacetsFromRoute($route) {
  if (!$route.query) {
    return [];
  }

  const searchFacets = {};

  Object.keys($route.query)
    .filter((key) => {
      // For all query params beginning with prefix
      return (
        key.indexOf(FACET_PREFIX) === 0 && key.length > FACET_PREFIX.length
      );
    })
    .forEach((key) => {
      // Remove prefix and split on delimiter
      searchFacets[key.split(FACET_PREFIX)[1]] =
        $route.query[key].split(FACET_DELIMITER);
    });

  return searchFacets;
}

function getSortFromRoute($route): string | null {
  return $route.query.sort || null;
}

function getPageFromRoute($route): number {
  if (typeof $route.query.page !== "undefined") {
    return Number($route.query.page);
  }

  return 0;
}

function getPreviewFromRoute($route): string {
  return $route.query.PREVIEW;
}

function getContentList(
  searchResult: SearchResult | null,
  contentListId: string,
): any {
  if (!searchResult) {
    return;
  }

  const contentList = searchResult.contentLists.find(
    (contentList: ContentList) => {
      return contentList.id === contentListId;
    },
  );

  if (!contentList || !contentList.items) {
    return;
  }

  return contentList;
}

function getDefaultRouteNameFromSearchResult(
  searchFacets: any,
  searchResult: SearchResult,
): string {
  if (Object.keys(searchFacets).length > 0) {
    return SearchPageRoutes.Products;
  }
  if (
    searchResult.primaryList &&
    searchResult.primaryList.productGroups &&
    searchResult.primaryList.productGroups.length > 0
  ) {
    return SearchPageRoutes.Products;
  }

  const articlesContentList = getContentList(
    searchResult,
    CONTENT_LIST_ARTICLES,
  );

  if (articlesContentList && articlesContentList.totalHits > 0) {
    return SearchPageRoutes.Articles;
  }

  const storesContentList = getContentList(searchResult, CONTENT_LIST_STORES);

  if (storesContentList && storesContentList.totalHits > 0) {
    return SearchPageRoutes.Stores;
  }

  return SearchPageRoutes.Products;
}
</script>
