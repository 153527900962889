<script lang="ts" setup>
import { formatPrice } from "../../mixins/productPrice.mixin";
import DeliveryWindows from "./deliveryWindows.vue";
import DeliveryLocations from "./deliveryLocations.vue";
import {
  DeliveryMethod,
} from "../../utils/API/Delivery/deliveryAPISchema";
import { CheckoutSteps } from "../../services/models/checkout.models";
import {
  deliveryWindowTimeStamp,
  formatDay,
  formatDeliveryLocationAddress,
  LogoNameForDeliveryMethod,
  hasDeliveryWindows,
  DeliveryMethods,
} from "../../../../helpers/checkoutHelpers";
import Icon from "../icon/icon.vue";
import { useDeliveryInformationStore } from "./stores/deliveryInformationStore";
import { useCheckoutStateStore } from "./stores/checkoutStateStore";
import * as CheckoutService from "../../services/checkout.service";


const props = defineProps<{
  isActive?: boolean;
}>();

const deliveryInformationStore = useDeliveryInformationStore();
const checkoutStateStore = useCheckoutStateStore();

const submitForm = () => {
  CheckoutService.goToCheckoutStep(CheckoutSteps.Payment);
};

/**
 * Sets the selected delivery method ID.
 *
 * @param {string} id - The ID of the selected delivery method.
 * @returns {void}
 */
const handleSetDeliveryMethod = (deliveryMethod: DeliveryMethod) => {
  deliveryInformationStore.setSelectedDeliveryMethod(deliveryMethod);
};

/**
 * Determines whether to display the delivery method based on the provided delivery method object.
 *
 * @param {DeliveryMethod} deliveryMethod - The delivery method object.
 * @returns {boolean} - Returns true if the delivery method should be displayed, otherwise false.
 */
const displayDeliveryMethod = (deliveryMethod: DeliveryMethod) => {
  if (
    deliveryMethod?.name === DeliveryMethods.Porterbuddy &&
    !hasDeliveryWindows(deliveryMethod)
  ) {
    return false;
  }

  return true;
};
</script>

<template>
  <div>
    <div
      v-if="!props.isActive && deliveryInformationStore.deliveryInformation"
      class="checkout-form__inactive"
    >
      <span>
        <span v-if="deliveryInformationStore.selectedDeliveryMethod">{{
          deliveryInformationStore.selectedDeliveryMethod?.displayName
        }}</span>
        <span v-if="deliveryInformationStore.selectedDeliveryLocation"
          ><div>
            {{ deliveryInformationStore.selectedDeliveryLocation?.name }}
          </div>
          <div>
            {{
              formatDeliveryLocationAddress(
                deliveryInformationStore.selectedDeliveryLocation,
              )
            }}
          </div></span
        >
        <div v-if="deliveryInformationStore.selectedDeliveryWindow">
          {{
            formatDay(
              new Date(deliveryInformationStore.selectedDeliveryWindow.end),
            )
          }}
          {{
            deliveryWindowTimeStamp(
              new Date(deliveryInformationStore.selectedDeliveryWindow.end),
            )
          }}-{{
            deliveryWindowTimeStamp(
              new Date(deliveryInformationStore.selectedDeliveryWindow.end),
            )
          }}
          <span v-if="deliveryInformationStore.selectedDeliveryMethod?.price">
            -
            {{
              formatPrice(
                deliveryInformationStore.selectedDeliveryMethod?.price,
              )
            }}</span
          >
        </div>
      </span>
    </div>
    <form
      v-if="props.isActive && deliveryInformationStore.deliveryInformation"
      class="checkout-form checkout-delivery"
      novalidate
      @submit.prevent="submitForm"
    >
      <fieldset>
        <legend>{{ $t("deliveryMethods") }}</legend>
        <div
          v-for="deliveryMethod in deliveryInformationStore?.deliveryInformation
            ?.deliveryMethods || []"
          v-if="
            (deliveryMethod: DeliveryMethod) =>
              displayDeliveryMethod(deliveryMethod)
          "
          :key="deliveryMethod.id"
          class="checkout-form__radio"
        >
          <label class="checkout-form__label" :for="deliveryMethod.id">
            <input
              :id="deliveryMethod.id"
              type="radio"
              name="delivery-method"
              :value="deliveryMethod.displayName"
              :checked="deliveryMethod === deliveryInformationStore.selectedDeliveryMethod"
              @click="handleSetDeliveryMethod(deliveryMethod)"
            />
            <div class="checkout-form__radio-heading">
              {{ deliveryMethod.displayName }}
              {{ formatPrice(deliveryMethod.price) }}
            </div>
            <div class="checkout-form__radio-content">
              <div class="checkout-form__radio-description">
                <div>
                  <span v-if="deliveryMethod.description">
                    {{ deliveryMethod.description.trim() }}
                  </span>
                  <span v-if="deliveryMethod.deliveryWindows?.length">
                    ({{ $t("deliveryWindowTip") }})
                  </span>
                </div>
                <Icon
                  v-if="LogoNameForDeliveryMethod(deliveryMethod)"
                  :type="LogoNameForDeliveryMethod(deliveryMethod)"
                  :title="`${deliveryMethod.displayName} logo`"
                  size="delivery-logo"
                  class="checkout-delivery-icon checkout-delivery-icon--porterbuddy"
                />
              </div>
              <DeliveryWindows
                v-if="
                  deliveryMethod.deliveryWindows &&
                  deliveryMethod === deliveryInformationStore.selectedDeliveryMethod
                "
                :delivery-windows="deliveryMethod.deliveryWindows"
                :selected-delivery-window="deliveryInformationStore.selectedDeliveryWindow"
              />
              <DeliveryLocations
                v-if="
                  deliveryMethod.deliveryLocations &&
                  deliveryMethod.deliveryLocations.length &&
                  deliveryMethod === deliveryInformationStore.selectedDeliveryMethod
                "
                :delivery-locations="deliveryMethod.deliveryLocations"
                :selected-delivery-location-id="
                  deliveryInformationStore.selectedDeliveryLocation?.id
                "
              />
            </div>
          </label>
        </div>
        <button
          :disabled="checkoutStateStore.isLoading"
          class="btn btn--profile btn--spinner btn--checkout btn--smaller checkout-form__submit"
          type="submit"
        >
          <Icon
            v-if="checkoutStateStore.isLoading"
            type="spinner"
            size="tiny"
            class="icon--spinner"
          ></Icon>
          <span v-if="checkoutStateStore.isLoading">{{ $t("loading") }}</span>
          <span v-if="!checkoutStateStore.isLoading">{{ $t("continue") }}</span>
        </button>
      </fieldset>
    </form>
  </div>
</template>
