import {
    hasDeliveryLocations,
    hasDeliveryWindows,
} from "../../../../../helpers/checkoutHelpers";
import { z } from "zod";
import { FEEDBACK_TEXT } from "../../validationHelpers";
import { citySchema, postalCodeSchema } from "../Cart/cartAPISchema";

export type Price = z.infer<typeof PriceSchema>;
export const PriceSchema = z.object({
    currency: z.string(),
    fractionalDenomination: z.number(),
});

export type PickupWindow = z.infer<typeof PickupWindowSchema>;
export const PickupWindowSchema = z.object({
    start: z.string(),
    end: z.string(),
    token: z.string(),
    price: PriceSchema,
});

export type DeliveryWindow = z.infer<typeof DeliveryWindowSchema>;
export const DeliveryWindowSchema = PickupWindowSchema.extend({
    consolidated: z.boolean(),
    displayPrice: PriceSchema,
    expiresAt: z.string(),
    price: PriceSchema,
    product: z.string(),
});

export type UpdateDeliveryRequestDto = z.infer<
    typeof UpdateDeliveryRequestDtoSchema
>;
export const UpdateDeliveryRequestDtoSchema = z.object({
    deliveryLocationId: z.string().optional(),
    deliveryWindow: DeliveryWindowSchema.optional(),
});

export type DeliveryLocation = z.infer<typeof DeliveryLocationSchema>;
export const DeliveryLocationSchema = z.object({
    id: z.string(),
    IsPickUpInStore: z.boolean().optional(),
    name: z.string().optional(),
    deliveryAddress: z.string().optional(),
    postalCode: postalCodeSchema.optional(),
    city: citySchema.optional(),
});

export type DeliveryMethod = z.infer<typeof DeliveryMethodSchema>;
export const DeliveryMethodSchema = z.object({
    name: z.string(),
    id: z.string().uuid(),
    price: z.number(),
    description: z.string().nullable(),
    displayName: z.string(),
    isDefaultSelection: z.boolean(),
    ordering: z.number(),
    deliveryWindows: z.array(DeliveryWindowSchema).nullable(),
    deliveryLocations: z.array(DeliveryLocationSchema).nullable(),
});

export type DeliveryModel = z.infer<typeof DeliveryModelSchema>;
export const DeliveryModelSchema = z.object({
    deliveryMethods: z.array(DeliveryMethodSchema),
});

export const createAddDeliveryMethodRequestDtoSchema = (
    selectedDeliveryMethod: DeliveryMethod,
) => {
    return UpdateDeliveryRequestDtoSchema.refine(
        (data) => {
            if (
                hasDeliveryWindows(selectedDeliveryMethod) &&
                !data.deliveryWindow?.token
            ) {
                return false;
            }
            return true;
        },
        {
            message: FEEDBACK_TEXT().REQUIRED_DELIVERY_WINDOW,
            path: ["deliveryWindow"],
        },
    ).refine(
        (data) => {
            if (
                hasDeliveryLocations(selectedDeliveryMethod) &&
                !data.deliveryLocationId
            ) {
                return false;
            }
            return true;
        },
        {
            message: FEEDBACK_TEXT().REQUIRED_DELIVERY_LOCATION,
            path: ["deliveryLocation"],
        },
    );
};
