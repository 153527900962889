<template>
  <div class="product-list" @scrollIntoView="handleScrollIntoView">
    <!-- Heading -->
    <h2 v-if="showComponent" class="product-list__heading">
      {{ heading }}
    </h2>
    <!-- Result -->
    <ul
      v-if="showComponent"
      id="category-list"
      class="product-list__items product-list__items--auto-width"
      :class="{
        'product-list__items--column': showAsColumnOnMobile === 'True',
      }"
      data-layout="4"
    >
      <template
        v-for="productGroup in productsResult?.primaryList?.productGroups"
      >
        <template v-if="productGroup.products && productGroup.products[0]">
          <li :key="`${productGroup.key}-${productGroup.products[0].key}`">
            <ProductTeaser
              :product-group="productGroup"
              :product="productGroup.products[0]"
              :is-user-authenticated="isUserAuthenticated"
              :is-favorite-form-hidden="isFavoriteFormHidden"
              :favorites="favorites"
              :is-loading-favorites="isLoadingFavorites"
              :use-gtm-tracking="true"
              :user-id="''"
              @toggleFavorite="toggleFavorite($event)"
            />
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Lazy from "../../mixins/lazy";
import Favorites from "../../mixins/favorites";
import SearchService, { ProductsResult } from "../../services/search.service";
import ProductTeaser from "../../components/productTeaser/productTeaser.vue";

export default defineComponent({
  components: {
    ProductTeaser,
  },
  mixins: [Lazy, Favorites],
  props: [
    "heading",
    "baseUrlPath",
    "blockId",
    "apiUrl",
    "userAuthenticated",
    "favoriteFormHidden",
    "lazy",
    "limit",
    "showAsColumnOnMobile",
  ],
  data() {
    return {
      isLoading: false,
      hasFetched: false,
      productsResult: null as ProductsResult | null,
    };
  },
  computed: {
    isFavoriteFormHidden(): boolean {
      return this.favoriteFormHidden === "True";
    },
    isUserAuthenticated(): boolean {
      return this.userAuthenticated === "True";
    },
    isLazy(): boolean {
      return this.lazy === "True";
    },
    showComponent(): boolean {
      return (
        this.productsResult !== null &&
        (this.productsResult?.primaryList?.productGroups?.length ?? 0) > 0
      );
    },
  },
  mounted() {
    if (!this.isLazy) {
      this.fetch();
    }

    if (this.isUserAuthenticated) {
      this.getFavorites();
    }
  },
  methods: {
    /**
     * Fetch
     */
    fetch() {
      if (this.hasFetched) {
        return;
      }

      this.isLoading = true;

      SearchService.products(this.apiUrl, this.baseUrlPath, this.limit)
        .then((response) => {
          this.hasFetched = true;
          this.productsResult = response.data;
        })
        .catch((error) => {
          this.isLoading = false;

          console.error(error);
        });
    },
    handleScrollIntoView() {
      if (this.isLazy) {
        this.fetch();
      }
    },
  },
});
</script>
