import {
    PlaceholderAddressModel,
    PlaceholderAddressModelSchema,
} from "../Cart/cartAPISchema";
import { handleGtmTracking } from "../GTM";
import { LogErrorResponse } from "../Logging/LoggingAPI";

const API_URL = "/api/customer";

export async function getCustomerInformation(): Promise<PlaceholderAddressModel | null> {
    const url = `${API_URL}`;
    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (response.status === 204) {
        return null;
    };

    const data = await response.json();
    const validatedData = PlaceholderAddressModelSchema.parse(data);

    if (validatedData.googleAnalyticsDataLayerObject) {
        handleGtmTracking(validatedData.googleAnalyticsDataLayerObject);
    }

    return validatedData;
}
