import { Product } from "../services/models/search.models";
import {
    FacetTypes,
    Facet,
    FacetValue,
    SizeType,
    Format,
} from "../services/search.service";

/**
 * Get selected values from facet
 */
export function getSelectedValuesFromFacet(facet: Facet): Array<FacetValue> {
    if (facet.type === FacetTypes.Color || facet.type === FacetTypes.Text) {
        return (facet.values || [])
            .filter((value: FacetValue) => {
                return value.selected;
            })
            .map((value: FacetValue) => {
                return value;
            });
    }

    if (facet.type === FacetTypes.Size) {
        const values: Array<FacetValue> = [];

        (facet.sizeTypes || []).forEach((sizeType: SizeType) => {
            sizeType.formats.forEach((format: Format) => {
                format.values.forEach((value: FacetValue) => {
                    if (value.selected) {
                        values.push(value);
                    }
                });
            });
        });

        return values;
    }

    return [] as Array<FacetValue>;
}

/**
 * Get selected value ids from facet
 */
export function getSelectedValueIdsFromFacet(facet: Facet): Array<string> {
    return getSelectedValuesFromFacet(facet).map((facetValue) => facetValue.id);
}

export const getCustomApptusValue = (
    customObject: Product,
    property: string,
    array = 0,
): string => {
    if (
        !(
            customObject &&
            customObject.custom &&
            customObject.custom[property] &&
            customObject.custom[property][array] &&
            customObject.custom[property][array].label
        )
    ) {
        return "";
    } else {
        return customObject.custom[property][0].label;
    }
};
