import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            lazyObserver: null as IntersectionObserver | null,
        };
    },
    mounted() {
        if (!IntersectionObserver) {
            this.$emit("scrollIntoView");
        }

        const lazyObserver = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting === true) {
                    this.$emit("scrollIntoView");
                }
            },
            { threshold: [0] },
        );

        lazyObserver.observe(this.$el);
    },
    beforeDestroy() {
        if (!this.lazyObserver) {
            return;
        }

        this.lazyObserver.unobserve(this.$el);
    },
});
