import { USER_FEEDBACK } from "../APIErrors";
import {
    AddPaymentMethodRequestDto,
    PaymentMethodViewModel,
    PaymentMethodViewModelSchema,
    SelectPaymentResultDto,
    SelectPaymentResultDtoSchema,
} from "./paymentAPISchema";
import { LogErrorResponse } from "../Logging/LoggingAPI";

const API_URL = "/api/payment";

export const getPaymentMethods = async (): Promise<
    PaymentMethodViewModel[]
> => {
    const url = `${API_URL}`;
    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        return Promise.reject(USER_FEEDBACK.GENERIC);
    }

    const data = await response.json();
    const validatedData = data.map((paymentOption: PaymentMethodViewModel) =>
        PaymentMethodViewModelSchema.parse(paymentOption),
    );
    
    return validatedData;
};

export const setPaymentMethod = async (
    paymentId: string,
    addPaymentMethodRequestDto: AddPaymentMethodRequestDto,
): Promise<SelectPaymentResultDto> => {
    const url = `${API_URL}/${paymentId}`;
    const body = JSON.stringify(addPaymentMethodRequestDto);
    const response = await fetch(url.toString(), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: body,
    });

    console.log("response", response);

    if (!response.ok) {
        console.log("!response.ok");
        LogErrorResponse(url, response, body);
        return {};
    }
    const data = await response.json();
    console.log("data", data);
    const validatedData = SelectPaymentResultDtoSchema.parse(data);
    console.log("api validatedData", validatedData);
    return validatedData;
};
