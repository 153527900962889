<script lang="ts" setup>
import { AddressModel, Cart } from "../../utils/API/Cart/cartAPISchema";
import ShoppingCartSummary from "../shoppingCart/shoppingCartSummary.vue";
import {
  CheckoutSteps,
  CheckoutStep,
} from "../../services/models/checkout.models";
import CustomerInformationForm from "./customerInformationForm.vue";
import DeliveryInformationForm from "./deliveryInformationForm.vue";
import PaymentInformationForm from "./paymentInformationForm.vue";
import CheckoutStepSelector from "./checkoutStepSelector.vue";
import CheckoutSummary from "./checkoutSummary.vue";
import {
  onMounted,
  computed,
  reactive,
  ref,
  watch,
  toRefs,
} from "vue";
import { formatWithoutRounding } from "../../mixins/productPrice.mixin";
import Accordion from "../accordion/accordion.vue";
import { useCustomerInformationStore } from "./stores/customerInformationStore";
import { useCheckoutStateStore } from "./stores/checkoutStateStore";
import { useDeliveryInformationStore } from "./stores/deliveryInformationStore";
import { usePaymentInformationStore } from "./stores/paymentInformationStore";
import * as CheckoutService from "../../services/checkout.service";

const customerInformationStore = useCustomerInformationStore();
const checkoutStateStore = useCheckoutStateStore();
const deliveryInformationStore = useDeliveryInformationStore();
const paymentInformationStore = usePaymentInformationStore();

const props = defineProps<{
  cart?: Cart;
  customerInformation?: AddressModel;
}>();

const deliveryInformationStoreRef = toRefs(deliveryInformationStore);

const steps: Array<CheckoutStep> = [
  CheckoutSteps.CustomerInformation,
  CheckoutSteps.Delivery,
  CheckoutSteps.Payment,
];

const completedSteps = computed(() => {
  return [
    ...(customerInformationStore.fields
      ? [CheckoutSteps.CustomerInformation]
      : []),
    ...(deliveryInformationStore.deliveryInformation ? [CheckoutSteps.Delivery] : []),
    ...(paymentInformationStore.selectedPaymentMethod?.systemKeyword ? [CheckoutSteps.Payment] : []),
  ];
});

const paymentInformationErrors = reactive<Record<string, string[]>>({
  personIdentifier: [],
  selectedAfterPayInstallmentOption: [],
  selectedAfterPayInstallmentPlan: [],
  paymentMethod: [],
  finalizeOrder: [],
});

export type PaymentInformationErrorKeyPaymentInformationErrorKey = keyof typeof paymentInformationErrors;

watch(deliveryInformationStoreRef?.selectedDeliveryMethod, (oldValue, newValue) => {
  if (!newValue) return;
  CheckoutService.submitDeliveryInformationForm();
});

/**
 * Computed property that generates the top text for the checkout component.
 * The top text includes the number of items in the cart and the total price.
 *
 * @returns {string} The formatted top text for the checkout component.
 */
const itemCountFormatted = computed(() => {
  return `${props.cart?.itemCount ?? 0} varer`;
});

/**
 * Computes and returns the formatted subtotal of the cart.
 *
 * @returns {string} The formatted subtotal of the cart.
 */
const subTotalFormatted = computed(() => {
  return props.cart && formatWithoutRounding(props.cart.total.amount);
});

onMounted(() => {
  if (props.customerInformation) {
    customerInformationStore.fields = props.customerInformation;
  }
});
</script>

<template>
  <div>
    <div class="layout-new-checkout__top">
      <h1 class="sr-only">Kasse</h1>
      <div v-if="props.cart" class="layout-new-checkout__top-content">
        <Accordion
          :heading="itemCountFormatted"
          :heading-second-part="subTotalFormatted"
          heading-class="layout-new-checkout__accordion-heading"
          extra-class="layout-new-checkout__top-content--padded"
        >
          <CheckoutSummary
            :cart="props.cart"
            :discount-total="props.cart?.discountTotal"
            :checkout-available="false"
            :show-checkout-link="false"
          />
        </Accordion>
      </div>
    </div>
    <div class="layout-new-checkout">
      <div class="layout-new-checkout__main">
        <div class="layout-new-checkout__vertical-split">
          <CheckoutStepSelector
            :steps="steps"
            :completed-steps="completedSteps"
            container-class="layout-new-checkout__primary"
            step-class="layout-new-checkout__element"
            heading-class="checkout-form__selector-heading"
            :active-step="checkoutStateStore.currentCheckoutStep"
          >
            <template #[steps[0]]>
              <CustomerInformationForm
                :is-active="checkoutStateStore.currentCheckoutStep === steps[0]"
              />
            </template>
            <template #[steps[1]]>
              <DeliveryInformationForm
                :is-active="checkoutStateStore.currentCheckoutStep === steps[1]"
              />
            </template>
            <template #[steps[2]]>
              <div v-if="checkoutStateStore.currentCheckoutStep === steps[2]">
                <PaymentInformationForm
                  :is-active="
                    checkoutStateStore.currentCheckoutStep === steps[2]
                  "
                >
                </PaymentInformationForm>
              </div>
            </template>
          </CheckoutStepSelector>
          <div
            class="layout-new-checkout__secondary layout-new-checkout__secondary--desktop-only"
          >
            <div class="layout-new-checkout__element">
              <ShoppingCartSummary
                :cart="props.cart"
                :show-checkout-link="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
