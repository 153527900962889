<template>
  <div class="u-position-relative">
    <!-- Preview bar -->
    <template v-if="isPreview">
      <PreviewBar v-if="isPreview" @clickExit="clickExitPreview()" />
    </template>
    <!-- Category result -->
    <template v-if="categoryResult">
      <!-- Loader -->
      <div v-if="isLoading" class="loader-wrapper">
        <div class="loader" />
      </div>
      <!-- Message -->
      <div
        v-if="categoryResult && categoryResult.message"
        class="u-text-center"
        role="alert"
      >
        {{ categoryResult.message }}
      </div>
      <!-- Products -->
      <products
        v-if="
          categoryResult &&
          categoryResult.primaryList &&
          categoryResult.primaryList.productGroups &&
          categoryResult.primaryList.productGroups.length > 0
        "
        v-bind="categoryResult"
        :subcategories="subcategories"
        :heading="heading"
        :searchpage="false"
        :is-user-authenticated="isUserAuthenticated"
        :is-favorite-form-hidden="isFavoriteFormHidden"
        :there-are-more-pages="thereAreMorePages"
        :favorites="favorites"
        :is-loading-favorites="isLoadingFavorites"
        :use-product-gtm-tracking="true"
        :user-id="userId"
        @upsertFacet="upsertFacet($event)"
        @useFilter="ApplyFilter()"
        @nextPage="nextPage()"
        @toggleFavorite="clickFavorite($event)"
        @updateSort="updateSort($event)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import axios, { CancelTokenSource } from "axios";
import {
  pushDataToGtm,
  getCategoryImpressionsEvent,
  getFilterImpressionsEvent,
} from "../../mixins/tracking";
import { getFavorites, toggleFavorite } from "../../mixins/favorites";
import { routerPush } from "../../mixins/routing";
import SearchService, {
  CategoryResult,
  Facet,
} from "../../services/search.service";
import PreviewBar from "../../components/previewBar/previewBar.vue";
import Products from "./../searchPage/products.vue";
import { useRoute, useRouter } from "vue-router";

const FACET_PREFIX = "filter.";
const FACET_DELIMITER = ",";
const CANCEL_SEARCH_REQUEST = "CancelSearchRequest";

enum TrackingEvents {
  CategoryPage = "categoryPage",
  FiltePage = "FilterPage",
}

export type SubCategories =
  | { name: string; url: string; isSelected: boolean }[]
  | null;
//Dataset means all props are strings for now
const props = defineProps<{
  userId: string;
  store: string;
  heading: string;
  category: string;
  subcategoriesIncoming?: string | null;
  favoriteFormHidden: string;
  userAuthenticated: string;
  pageSize: string;
  pageId: string;
  tracking: string;
}>();

const isLoading = ref(false);
const page = ref(0);
const cancelTokenSource = ref(null as CancelTokenSource | null);
const searchFacets = ref({});
const categoryResult = ref(null as CategoryResult | null);
const sort = ref(null as string | null);
const preview = ref(undefined as string | undefined);
const isLoadingFavorites = ref(false);
const favorites = ref(null as Array<string> | null);

// Get routes
const route = useRoute();
const router = useRouter();

const pageSizeNumber = computed((): number => {
  return props.pageSize ? Number(props.pageSize) : 12;
});

const thereAreMorePages = computed((): boolean => {
  if (!categoryResult.value || !categoryResult.value.primaryList) {
    return false;
  }

  const limit = (page.value + 1) * pageSizeNumber.value;

  return categoryResult.value.primaryList.totalHits > limit;
});

const subcategories = computed(() => {
  if (!props.subcategoriesIncoming) return null;

  try {
    const subcategories = JSON.parse(props.subcategoriesIncoming);
    // const doubledSubcats = [
    //   ...subcategories.map((subcat) => ({ ...subcat, isSelected: false })),
    //   ...subcategories,
    // ];
    // doubledSubcats[0].name = "Vis alle";
    // return doubledSubcats;
    subcategories[0].name = "Vis alle";
    return subcategories;
  } catch {
    return [];
  }
});

//We're getting booleans as string. Convert to bools
const isFavoriteFormHidden = computed((): boolean => {
  return props.favoriteFormHidden === "True";
});
const isUserAuthenticated = computed((): boolean => {
  return props.userAuthenticated === "True";
});
const isPreview = computed((): boolean => {
  return preview.value === "true";
});

onMounted(() => {
  if (isUserAuthenticated.value) {
    isLoadingFavorites.value = true;
    getFavorites()
      .then((response) => {
        favorites.value = response?.data.map((i) => String(i)) || null;
      })
      .finally(() => (isLoadingFavorites.value = false));
  }
});

watch(
  [() => route.fullPath, () => route.hash],
  ([toPath, toHash], [fromPath, fromHash]) => {
    /**
     * Just hash change: Don't search
     */
    if (
      toHash &&
      fromHash &&
      fromPath &&
      toHash !== fromHash &&
      toPath.replace(toHash, "") === fromPath.replace(fromHash, "")
    ) {
      return;
    }
    /**
     * Initial navigation: Trigger search
     */
    if (!fromPath) {
      search();
      return;
    }
    /**
     * Route has changed: Trigger search
     */
    if (toPath !== fromPath) {
      search();
    }
  },
  { immediate: true },
);

watch(categoryResult, () => {
  if (!categoryResult.value || !categoryResult.value.primaryList) {
    return;
  }

  if (props.tracking === TrackingEvents.CategoryPage) {
    const tracking = getCategoryImpressionsEvent({
      userId: props.userId,
      store: props.store,
      productGroups: categoryResult.value.primaryList.productGroups,
    });

    pushDataToGtm(tracking);
  } else if (props.tracking === TrackingEvents.FiltePage) {
    const tracking = getFilterImpressionsEvent({
      store: props.store,
      listName: "",
      productGroups: categoryResult.value.primaryList.productGroups,
    });
    pushDataToGtm(tracking);
  }
});

function clickFavorite(productKey: string) {
  isLoadingFavorites.value = true;
  toggleFavorite(productKey)
    .then((response) => {
      favorites.value = response?.data.map((i) => String(i)) || null;
    })
    .finally(() => (isLoadingFavorites.value = false));
}

/**
 * Search
 */
function search() {
  searchFacets.value = getSearchFacetsFromRoute(route);
  sort.value = getSortFromRoute(route);
  page.value = getPageFromRoute(route);
  preview.value = getPreviewFromRoute(route);

  if (cancelTokenSource.value) {
    // Cancel previous request
    cancelTokenSource.value.cancel(CANCEL_SEARCH_REQUEST);

    cancelTokenSource.value = null;
  }

  isLoading.value = true;

  const localCancelTokenSource = (cancelTokenSource.value =
    axios.CancelToken.source());

  const searchPromise = SearchService.category(
    localCancelTokenSource,
    props.pageId,
    searchFacets.value,
    sort.value,
    pageSizeNumber.value,
    page.value,
    preview.value,
  );

  searchPromise
    .then((result) => {
      categoryResult.value = result.data;
      isLoading.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
      categoryResult.value = null;

      if (error.message !== CANCEL_SEARCH_REQUEST) {
        console.error(error);
      }
    });
}
/**
 * Upsert facet
 */
function upsertFacet({
  facet,
  selectedValues,
  useFilter = false,
}: {
  facet: Facet;
  selectedValues: Array<string>;
  useFilter: boolean;
}) {
  searchFacets.value[facet.id] = selectedValues;
  if (useFilter) {
    ApplyFilter();
  }
}
/**
 * Update sort
 */
function updateSort(newSort: string): void {
  sort.value = newSort;
}
/**
 * Use filter
 */
function ApplyFilter() {
  const currentRouterQuery = route.query;
  const newRouterQuery = Object.assign({}, currentRouterQuery);

  Object.keys(searchFacets.value).forEach((facetId) => {
    const selectedValues = searchFacets.value[facetId];

    newRouterQuery[`${FACET_PREFIX}${facetId}`] =
      selectedValues.join(FACET_DELIMITER);

    if (selectedValues.length === 0) {
      delete newRouterQuery[`${FACET_PREFIX}${facetId}`];
    }
  });
  delete newRouterQuery["page"];

  if (sort.value) {
    newRouterQuery.sort = sort.value;
  } else {
    delete newRouterQuery.sort;
  }

  routerPush(
    {
      name: route.name,
      query: newRouterQuery,
    },
    router,
  );
}
/**
 * Next page
 */
function nextPage() {
  const page = getPageFromRoute(route);

  routerPush(
    {
      name: route.name,
      query: {
        ...route.query,
        page: page + 1,
      },
    },
    router,
  );
}
/**
 * Click exit preview
 */
function clickExitPreview() {
  routerPush(
    {
      name: route.name,
      query: {
        q: route.query.q,
      },
    },
    router,
  );
}

function getSearchFacetsFromRoute($route) {
  if (!$route.query) {
    return [];
  }

  const searchFacets = {};

  Object.keys($route.query)
    .filter((key) => {
      // For all query params beginning with prefix
      return (
        key.indexOf(FACET_PREFIX) === 0 && key.length > FACET_PREFIX.length
      );
    })
    .forEach((key) => {
      // Remove prefix and split on delimiter
      searchFacets[key.split(FACET_PREFIX)[1]] =
        $route.query[key].split(FACET_DELIMITER);
    });

  return searchFacets;
}

function getSortFromRoute($route): string | null {
  return $route.query.sort || null;
}

function getPageFromRoute($route): number {
  if (typeof $route.query.page !== "undefined") {
    return Number($route.query.page);
  }

  return 0;
}

function getPreviewFromRoute($route): string {
  return $route.query.PREVIEW;
}
</script>
