import { Cart, CartSchema } from "../Cart/cartAPISchema";
import {
    DeliveryModel,
    DeliveryModelSchema,
    UpdateDeliveryRequestDto,
} from "./deliveryAPISchema";
import { handleGtmTracking } from "../GTM";
import { LogErrorResponse } from "../Logging/LoggingAPI";

const API_URL = "/api/delivery";

/**
 * Retrieves delivery information from the API.
 *
 * @returns A promise that resolves to a `DeliveryModel` object.
 * @throws An error if the HTTP request fails or the response is not valid.
 */
export async function getDeliveryInformation(): Promise<DeliveryModel> {
    const url = `${API_URL}`;
    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const validatedData = DeliveryModelSchema.parse(data);
    return validatedData;
}

/**
 * Sets the selected delivery option for a given delivery method.
 *
 * @param request - The selected delivery option schema.
 * @returns A promise that resolves to a boolean indicating whether the delivery option was set successfully.
 */
export async function setDeliveryOption(
    deliveryMethodId: string,
    request: UpdateDeliveryRequestDto,
): Promise<Cart> {
    const url = `${API_URL}/${deliveryMethodId}`;
    const body = JSON.stringify(request);
    const response = await fetch(url.toString(), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    });

    if (!response.ok) {
        await LogErrorResponse(url, response, body);
        return Promise.reject(false);
    }

    const data = await response.json();
    const validatedData = CartSchema.parse(data);
    handleGtmTracking(validatedData.googleAnalyticsDataLayerObject);

    return validatedData;
}
