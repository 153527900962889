<template>
  <div ref="root" class="checkout-line-item skeleton__container">
    <div class="checkout-line-item__container">
      <div class="checkout-line-item__photo skeleton__img"></div>
      <div class="checkout-line-item__content">
        <div class="skeleton__heading"></div>
        <div class="checkout-line-item__details">
          <div class="checkout-line-item__price">
            <div class="skeleton__text skeleton__text--long"></div>
          </div>
          <div class="checkout-line-item__size">
            <div class="skeleton__text"></div>
          </div>
          <div class="checkout-line-item__color">
            <div class="skeleton__text skeleton__text"></div>
          </div>
        </div>
        <div class="cart-item__actions checkout-line-item__quantity">
          <div class="skeleton__quantity"></div>
        </div>
      </div>
      <div class="checkout-line-item__remove-item">
        <div class="skeleton__remove-item"></div>
      </div>
    </div>
  </div>
</template>
