import { pushDataToGtm } from "../../../../global/apps/mixins/tracking";
import { EcommerceDataLayer } from "./GoogleAnalytics/GoogleAnalyticsSchema";

const USE_GTM_TRACKING = true;

/**
 * Handles GTM tracking by pushing data to the data layer object.
 *
 * @param dataLayerObject - The data layer object containing ecommerce data.
 */
export const handleGtmTracking = (
    dataLayerObject: EcommerceDataLayer | null,
) => {
    if (!USE_GTM_TRACKING || !dataLayerObject) return;

    pushDataToGtm(dataLayerObject);
};
