<template>
  <div>
    <!-- Search result: Facets -->
    <div
      v-if="primaryList && primaryList.facets"
      class="layout-searchpage__filters"
      :class="{
        'is-active': showFacets,
      }"
    >
      <template v-if="searchpage">
        <h2
          v-if="
            primaryList &&
            primaryList.productGroups &&
            primaryList.productGroups.length > 0
          "
          class="layout-searchpage__subheading"
        >
          {{ $t("hitsIn") }} {{ $t("products") }}
          <span class="layout-searchpage__hit-count"
            >({{ primaryList.totalHits }} {{ $t("hits") }})</span
          >
        </h2>
      </template>
      <template v-else>
        <h1 class="layout-category__heading">
          {{ heading }}
          <span
            v-if="
              primaryList &&
              primaryList.productGroups &&
              primaryList.productGroups.length > 0
            "
            class="layout-category__heading__count"
          >
            {{ primaryList.totalHits }} {{ $t("styles") }}
          </span>
        </h1>
      </template>
      <!-- Subcategories -->
      <template v-if="subcategories && subcategories.length != 0">
        <div class="product-subcategories">
          <template v-for="subcategory in subcategories" :key="subcategory.url">
            <a
              :href="subcategory.url"
              :class="{
                'is-active': subcategory.isSelected,
              }"
              class="product-subcategories__subcategory"
              >{{ subcategory.name }}</a
            >
          </template>
        </div>
      </template>
      <!-- End Subcategories -->
      <div class="layout-searchpage__mobile-filters">
        <button
          class="btn btn--category-filters"
          data-togglewhat="categoryMenu"
          aria-expanded="false"
          @click="showFacets = !showFacets"
          @keyup.esc="esc()"
        >
          <span class="u-text-smaller">Filtrer</span>
          <Icon type="sorting" />
        </button>
      </div>
      <div
        class="layout-category__navigation"
        :class="{
          'is-active': showFacets,
        }"
      >
        <div class="layout-category__navigation__scroll">
          <div
            class="product-filter__submit product-filter__submit--fixed u-hide-for-medium"
          >
            <button
              type="button"
              class="btn btn--inset btn--profile filter__btn-use"
              @click="showFacets = !showFacets"
            >
              {{ $t("close") }}
            </button>
          </div>
          <div class="product-filter__facet-wrapper">
            <Facets
              v-if="primaryList.facets"
              :facets="primaryList.facets"
              :sort="primaryList.sort"
              @upsertFacet="upsertFacet($event)"
              @updateSort="updateSort($event)"
              @useFilter="useFilter()"
            />
            <Pills
              v-if="primaryList.facets"
              :facets="primaryList.facets"
              @upsertFacet="upsertFacet($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Search result: Products -->
    <ul
      v-if="
        primaryList &&
        primaryList.productGroups &&
        primaryList.productGroups.length > 0
      "
      class="category-grid"
      data-layout="4"
    >
      <template v-for="productGroup in primaryList.productGroups">
        <template v-if="productGroup.products && productGroup.products[0]">
          <li
            :key="`${productGroup.key}-${productGroup.products[0].key}`"
            class="category-grid__item"
          >
            <ProductTeaser
              :product-group="productGroup"
              :product="productGroup.products[0]"
              :is-user-authenticated="isUserAuthenticated"
              :is-favorite-form-hidden="isFavoriteFormHidden"
              :favorites="favorites"
              :is-loading-favorites="isLoadingFavorites"
              :use-gtm-tracking="useProductGtmTracking"
              :user-id="userId"
              @toggleFavorite="toggleFavorite($event)"
            />
          </li>
        </template>
      </template>
      <!-- Load more -->
      <li
        v-if="thereAreMorePages"
        class="category-grid__item category-grid__item--loadmore pageNum-container"
      >
        <button class="btn btn--loadmore" @click="clickNextPage()">
          {{ $t("loadMore") }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import Facets from "../../components/search/facets.vue";
import Pills from "../../components/search/pills.vue";
import ProductTeaser from "../../components/productTeaser/productTeaser.vue";
import Icon from "../../components/icon/icon.vue";
import { PrimaryList } from "../../services/models/search.models";
import { SubCategories } from "../categoryPage/categoryPage.vue";

const props = defineProps<{
  heading?: string;
  subcategories?: SubCategories;
  searchpage: boolean;
  primaryList: PrimaryList | null;
  isFavoriteFormHidden: boolean;
  isUserAuthenticated: boolean;
  thereAreMorePages: boolean;
  favorites?: string[] | null;
  isLoadingFavorites: boolean;
  useProductGtmTracking: boolean;
  userId: string;
}>();

const showFacets = ref(false);

onMounted(() => {
  const focusedElement = document.getElementsByClassName("is-active")[0];
  (focusedElement as HTMLAnchorElement | null)?.focus();
  const focusedElementWrapper = document.getElementsByClassName(
    "product-subcategories",
  )[0];
  focusedElementWrapper?.scrollBy(50, 0);
});

const emit = defineEmits([
  "upsertFacet",
  "updateSort",
  "useFilter",
  "nextPage",
  "toggleFavorite",
]);

/**
 * Upsert facet
 */
function upsertFacet(payload) {
  emit("upsertFacet", payload);
}
/**
 * Update sort
 */
function updateSort(payload) {
  emit("updateSort", payload);
}
/**
 * Use filter
 */
function useFilter() {
  showFacets.value = false;

  emit("useFilter");
}
/**
 * Click next page
 */
function clickNextPage() {
  emit("nextPage");
}
/**
 * Toggle favorite
 */
function toggleFavorite(productKey: string) {
  emit("toggleFavorite", productKey);
}
/**
 * Esc
 */
function esc() {
  showFacets.value = false;
}
</script>
