<script lang="ts" setup>
import { ref, onMounted, computed, watch } from "vue";
import autoAnimate from "@formkit/auto-animate";
import Combobox from "../combobox/Combobox.vue";
import { DeliveryLocation } from "../../utils/API/Delivery/deliveryAPISchema";
import { formatDeliveryLocationAddress } from "../../../../helpers/checkoutHelpers";
import { useDeliveryInformationStore } from "./stores/deliveryInformationStore";

const props = defineProps<{
  deliveryLocations?: DeliveryLocation[];
  selectedDeliveryLocationId?: string;
}>();

const deliveryInformationStore = useDeliveryInformationStore();
const containerElement = ref();

/**
 * Creates and array of objects that satisfy the ComboboxOption interface for usage in the Combobox component.
 *
 * @returns {Array} The options for the combobox.
 */
const comboboxOptions = computed(() => {
  return (
    props.deliveryLocations?.map((location: DeliveryLocation) => ({
      heading: location.name || "",
      description: formatDeliveryLocationAddress(location),
      id: location.id,
    })) || []
  );
});

const setDeliveryLocationById = (id: string) => {
  const location = props.deliveryLocations?.find(
    (location) => location.id === id,
  );

  if (location) {
    deliveryInformationStore.setSelectedDeliveryLocation(location);
  }
};

onMounted(() => {
  autoAnimate(containerElement.value);
});
</script>

<template>
  <div ref="containerElement" class="checkout-delivery__locations">
    <Combobox
      @update:modelValue="setDeliveryLocationById"
      v-if="props.deliveryLocations"
      :heading="$t('selectDeliveryLocation')"
      :hide-heading="true"
      :options="comboboxOptions"
      :selected-id="deliveryInformationStore.selectedDeliveryLocation?.id"
    />
  </div>
</template>
