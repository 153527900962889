<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import {
  CheckoutStages,
  VerificationStep,
  VerificationSteps,
} from "../../services/models/checkout.models";
import FormInput from "../form/formInput.vue";
import { USER_FEEDBACK } from "../../utils/API/APIErrors";
import ShoppingCartError from "../shoppingCart/shoppingCartError.vue";
import autoAnimate from "@formkit/auto-animate";
import Icon from "../icon/icon.vue";
import {
  resetPasswordEmailVerificationSchema,
  verificationSchema,
} from "../../utils/API/Profile/profileAPISchema";
import {
  clearErrors,
  setInformationErrors,
} from "../../../../helpers/checkoutHelpers";
import { useCheckoutStateStore } from "../checkout/stores/checkoutStateStore";
import * as CheckoutService from "../../services/checkout.service";
import { login, resetPassword } from "../../utils/API/Profile/profileAPI";

const checkoutStateStore = useCheckoutStateStore();
const fieldsetElement = ref<HTMLElement | null>();
const verificationError = ref<string | undefined>();
const positiveFeedback = ref<string | undefined>();
const email = ref("");
const password = ref("");
const verificationInformationErrorsElement = ref<HTMLElement>();

const verificationInformationErrors = reactive<Record<string, string[]>>({
  email: [],
  password: [],
});

const currentVerificationStep = ref<VerificationStep>(
  VerificationSteps.DecideVerificationType,
);

/**
 * Clears the login information error.
 */
const clearLoginInformationError = () => {
  verificationError.value = undefined;
};

/**
 * Clears the positive feedback value.
 */
const clearPositiveFeedback = () => {
  positiveFeedback.value = undefined;
};

/**
 * Performs a login request to the API.
 *
 * @param {string} email - The user's email address.
 * @param {string} password - The user's password.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the login was successful.
 */
const apiLogin = async (email: string, password: string): Promise<boolean> => {
  const success = await login(email, password);
  return success;
};

/**
 * Calls the API to reset the password for the specified email.
 *
 * @param {string} email - The email address for which the password needs to be reset.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the password reset was successful.
 */
const apiResetPassword = async (email: string): Promise<boolean> => {
  checkoutStateStore.setIsLoading(true);

  const success = await resetPassword(email);

  checkoutStateStore.setIsLoading(false);
  return success;
};

/**
 * Submits the form for verification.
 * This will call the API to verify the user's login information.
 * If the login is successful, emit an event to go to the next step in the checkout process.
 * If the login fails, the user will receive an error message.
 *
 * @returns {Promise<void>} A promise that resolves when the form is submitted.
 */
const submitForm = async (): Promise<void> => {
  clearErrors(verificationInformationErrors);
  clearPositiveFeedback();
  clearLoginInformationError();
  checkoutStateStore.setIsLoading(true);

  const validationResult = verificationSchema.safeParse({
    email: email.value,
    password: password.value,
  });

  if (!validationResult.success && email.value && password.value) {
    verificationError.value = USER_FEEDBACK.LOGIN_FAILED;
  }

  if (!validationResult.success) {
    setInformationErrors(validationResult.error, verificationInformationErrors);
    checkoutStateStore.setIsLoading(false);
    return;
  }

  const successfulApiLogin = await apiLogin(email.value, password.value);
  password.value = "";

  if (!successfulApiLogin) {
    verificationError.value = USER_FEEDBACK.LOGIN_FAILED;
    checkoutStateStore.setIsLoading(false);
    return;
  }

  checkoutStateStore.setIsLoading(false);
  CheckoutService.goToCheckoutStageAfterLogin();
};

/**
 * Handles the action of proceeding as a guest. This will skip the login step and go directly to the checkout.
 */
const handleProceedAsGuest = () => {
  CheckoutService.goToCheckoutStage(CheckoutStages.Checkout);
};

/**
 * Handles the action to proceed with the login process. This will show the login form.
 */
const handleProceedWithLogin = () => {
  currentVerificationStep.value = VerificationSteps.Login;
};

/**
 * Handles the action of going back to the verification step where the user can decide the verification type.
 */
const handleBackToVerification = () => {
  currentVerificationStep.value = VerificationSteps.DecideVerificationType;
};

/**
 * Handles the reset password event.
 * This will call the API to reset the password for the specified email.
 * If the reset is successful, the user wilbl receive an email with instructions on how to reset the password.
 * If the reset fails, the user will receive an error message.
 *
 * @param {Event} event - The event object.
 * @returns {Promise<void>} - A promise that resolves when the password is reset.
 */
const handleResetPassword = async (): Promise<void> => {
  clearErrors(verificationInformationErrors);
  clearPositiveFeedback();
  clearLoginInformationError();

  const validationResult = resetPasswordEmailVerificationSchema.safeParse(
    email.value,
  );

  if (!validationResult.success) {
    verificationInformationErrors.email = validationResult.error.errors.map(
      (error) => error.message,
    );

    return;
  }

  const resetSuccessful = await apiResetPassword(email.value);

  if (!resetSuccessful) {
    verificationError.value = USER_FEEDBACK.RESET_PASSWORD_FAILED;
    return;
  }

  positiveFeedback.value = USER_FEEDBACK.RESET_PASSWORD_SUCCESS;
};

onMounted(() => {
  // Ensures that the next step is visible within the viewport
  window.scrollTo(0, 0);

  if (fieldsetElement.value) {
    autoAnimate(fieldsetElement.value);
  }

  if (verificationInformationErrorsElement.value) {
    autoAnimate(verificationInformationErrorsElement.value);
  }

  if (fieldsetElement.value) {
    autoAnimate(fieldsetElement.value);
  }
});
</script>

<template>
  <div>
    <div
      v-if="
        currentVerificationStep === VerificationSteps.DecideVerificationType
      "
    >
      <div class="layout-new-checkout layout-new-checkout--white">
        <div class="layout-new-checkout__top layout-new-checkout__top--white">
          <h1>{{ VerificationSteps.DecideVerificationType }}</h1>
        </div>
        <div class="layout-new-checkout__main">
          <div
            class="layout-new-checkout__element layout-new-checkout__element--small layout-new-checkout__element--centered layout-new-checkout__element--verification"
          >
            <div class="layout-new-checkout__loginpath">
              <div class="layout-new-checkout__loginpath-option">
                <h2>{{ $t("withoutProfile") }}</h2>
                <button
                  class="btn btn--profile btn--smaller checkout-form__submit"
                  @click="handleProceedAsGuest"
                >
                  {{ $t("proceedAsGuest") }}
                </button>
              </div>
              <div class="layout-new-checkout__loginpath-option">
                <h2>{{ $t("withProfile") }}</h2>
                <button
                  class="btn btn--white btn--smaller"
                  @click="handleProceedWithLogin"
                >
                  {{ $t("login") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentVerificationStep === VerificationSteps.Login">
      <div class="layout-new-checkout layout-new-checkout--white">
        <div>
          <button
            class="btn btn--link layout-new-checkout__back-button"
            @click="handleBackToVerification"
          >
            <Icon size="tiny" type="arrow"></Icon><span>Tilbake</span>
          </button>
        </div>
        <div class="layout-new-checkout__top layout-new-checkout__top--white">
          <h1>{{ VerificationSteps.Login }}</h1>
        </div>
        <div class="layout-new-checkout__main">
          <div
            class="layout-new-checkout__element layout-new-checkout__element--small layout-new-checkout__element--centered layout-new-checkout__element--login"
          >
            <div class="layout-new-checkout__loginpath">
              <form
                class="checkout-form"
                novalidate
                @submit.prevent="submitForm"
              >
                <fieldset ref="fieldsetElement">
                  <legend>{{ $t("loginWithProfile") }}</legend>
                  <span class="layout-new-checkout__verification-text">{{
                    $t("accessBenefits")
                  }}</span>
                  <div class="layout-new-checkout__verification-inputs">
                    <FormInput
                      v-model="email"
                      input-class="checkout-form__input"
                      :label="$t('email')"
                      label-class="checkout-form__label"
                      type="email"
                      :required="true"
                      :autofocus="true"
                      :error="verificationInformationErrors.email[0]"
                      autocomplete="email"
                      :disabled="checkoutStateStore.isLoading"
                    />
                    <FormInput
                      v-model="password"
                      input-class="checkout-form__input"
                      :label="$t('password')"
                      label-class="checkout-form__label"
                      type="password"
                      :required="true"
                      :error="verificationInformationErrors.password[0]"
                      autocomplete="password"
                      :disabled="checkoutStateStore.isLoading"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn--link btn--small layout-new-checkout__loginpath-forgotten-password"
                    @click.prevent.stop="() => handleResetPassword()"
                  >
                    {{ $t("forgottenPassword") }}
                  </button>
                  <ShoppingCartError
                    v-if="positiveFeedback"
                    :error="positiveFeedback"
                    type="success"
                  />
                  <ShoppingCartError :error="verificationError" type="global" />
                  <button
                    :disabled="checkoutStateStore.isLoading"
                    class="btn btn--profile btn--smaller"
                    type="submit"
                  >
                    <Icon
                      v-if="checkoutStateStore.isLoading"
                      type="spinner"
                      size="tiny"
                      class="icon--spinner"
                    ></Icon>
                    <span v-if="checkoutStateStore.isLoading">{{
                      $t("loading")
                    }}</span>
                    <span v-if="!checkoutStateStore.isLoading">{{
                      $t("login")
                    }}</span>
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
