<script setup lang="ts">
//Imports here
import { ref, onMounted } from 'vue';
import Icon from '../../components/icon/icon.vue';
import { ProductImageDto } from './productImageDto.model';

const SELECTED_THUMBNAIL_CLASS = 'image-zoom__thumbnail-selected';
const OVERFLOW_HIDDEN_CLASS = 'u-overflow-hidden';
const INTERSECTION_THRESHOLD = 0.2;
const SCROLL_LOGICAL_POSITION = 'start'; 
const ON_MOUNTED_SCROLL_BEHAVIOR = 'instant';
const AFTER_MOUNTED_SCROLL_BEHAVIOR = 'smooth';
const FIRST_VIDEO_FRAME_PARAMETER = '#t=0.1';

//Dataset means all props are strings for now
const props = defineProps<{
  video: string;
  images: string;
}>();

const dialog = ref<HTMLDialogElement>();
const isExpanded = ref<boolean>(false);
const imageDtos = ref<Array<ProductImageDto>>();
const body = ref<HTMLBodyElement | null>();
const imageThumbnails = ref<Array<HTMLImageElement> | null>();
const videoThumbnail = ref<HTMLVideoElement | null>();
const imageElements = ref<Array<HTMLImageElement> | null>();
const videoElement = ref<HTMLVideoElement | null>();
const intersectionObserver = ref<IntersectionObserver>();

onMounted(() => {
    imageDtos.value = JSON.parse(props.images);
    body.value = document.querySelector('body');
});

function addIntersectionObservers() {
    const intersectionObserver = new IntersectionObserver((entries) => handleIntersect(entries), { threshold: INTERSECTION_THRESHOLD } );

    imageElements.value?.forEach(image => {
        intersectionObserver.observe(image);
    });

    videoElement.value && intersectionObserver.observe(videoElement.value);
}

function removeIntersectionObservers() {
    imageElements.value?.forEach(image => {
        intersectionObserver.value?.unobserve(image);
    });

    videoElement.value && intersectionObserver.value?.unobserve(videoElement.value);
}

function handleIntersect(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        if (entry.target instanceof HTMLImageElement) {
            const intersectImage = imageDtos.value?.find(imageDto => imageDto.source === (entry.target as HTMLImageElement).src);
            if (intersectImage) selectImageThumbnail(intersectImage as ProductImageDto);
        }

        if (entry.target instanceof HTMLVideoElement) {
            selectVideoThumbnail();
        }
    });
}

function showDialog() {
    dialog.value?.showModal();
    preventBackgroundScroll(true);
    setIsExpanded(true);

    // If a videoElement exists, it is always the first element to be displayed
    if (videoElement.value) {
        scrollToVideo(ON_MOUNTED_SCROLL_BEHAVIOR);
        selectVideoThumbnail();
    } 

    // If no videoElement exists, display the first image element
    if (!videoElement.value && imageDtos.value) {
        scrollToImage(imageDtos.value[0], ON_MOUNTED_SCROLL_BEHAVIOR);
        selectImageThumbnail(imageDtos.value[0]);
    }

    addIntersectionObservers();
}

function closeDialog() {
    dialog.value?.close();
}

function handleClose() {
    removeIntersectionObservers();
    preventBackgroundScroll(false);
    setIsExpanded(false);
}

function setIsExpanded(expandedState: boolean) {
    isExpanded.value = expandedState;
}

// Preventing scroll on body element in order to keep its scrollbar from overlapping the one within the dialog
function preventBackgroundScroll(preventScroll: boolean) {
    if (!body.value) return;

    preventScroll
        ? body.value.classList.add(OVERFLOW_HIDDEN_CLASS)
        : body.value.classList.remove(OVERFLOW_HIDDEN_CLASS);
}

function handleImageThumbnailClick(image: ProductImageDto) {
    scrollToImage(image, AFTER_MOUNTED_SCROLL_BEHAVIOR);
}

function handleVideoThumbnailClick() {
    scrollToVideo(AFTER_MOUNTED_SCROLL_BEHAVIOR);
}

// eslint-disable-next-line no-undef
function scrollToImage(image: ProductImageDto, scrollBehavior: ScrollBehavior | undefined) {
    imageElements.value?.find(imageElement => imageElement.src == image.source)?.scrollIntoView({behavior: scrollBehavior, block: SCROLL_LOGICAL_POSITION});
}

// eslint-disable-next-line no-undef
function scrollToVideo(scrollBehavior: ScrollBehavior | undefined) {
    videoElement.value?.scrollIntoView({behavior: scrollBehavior, block: SCROLL_LOGICAL_POSITION});
}

function selectImageThumbnail(thumbnail: ProductImageDto) {
    if (!imageThumbnails.value) return;
    
    const selectedThumbnail = imageThumbnails.value.find(imageThumbnail => imageThumbnail.src === thumbnail.thumbnail);
    
    if (selectedThumbnail) {
        selectedThumbnail.classList.add(SELECTED_THUMBNAIL_CLASS);
        selectedThumbnail.ariaCurrent = 'true';
    }

    resetImageThumbnails(selectedThumbnail);
    resetVideoThumbnail();
}

function selectVideoThumbnail() {
    if (!videoThumbnail.value) return;

    videoThumbnail.value.classList.add(SELECTED_THUMBNAIL_CLASS);
    videoThumbnail.value.ariaCurrent = 'true';

    resetImageThumbnails();
}

function resetImageThumbnails(exception?: HTMLImageElement) {
    if (!imageThumbnails.value) return;

    imageThumbnails.value.forEach(thumbnail => {
        if (thumbnail === exception) return;
        
        thumbnail.classList.remove(SELECTED_THUMBNAIL_CLASS);
        thumbnail.ariaCurrent = 'false';
    });
}

function resetVideoThumbnail() {
    if (!videoThumbnail.value) return;
        
    videoThumbnail.value.classList.remove(SELECTED_THUMBNAIL_CLASS);
    videoThumbnail.value.ariaCurrent = 'false'; 
}

</script>
<template v-if="imageDtos.value && imageDtos.length">
  <div>
    <button
      class="image-zoom image-zoom__button-trigger"
      :aria-expanded="isExpanded"
      aria-haspopup="dialog"
      :tooltip="$t('highres').toString()"
      :aria-description="$t('highres').toString()"
      @click="showDialog()"
    >
      <Icon
        type="search"
        size="tiny"
        :title="$t('highres').toString()"
      />
    </button>
    <dialog
      ref="dialog"
      class="dialog dialog--fullscreen"
      @close="handleClose()"
    >
      <div class="dialog__layout">
        <div class="dialog__contents">
          <button
            class="dialog__close dialog__close--fixed btn btn--icon"
            :aria-label="$t('close').toString()"
            :title="$t('close').toString()"
            @click="closeDialog()"
          >
            <Icon
              type="close"
              :title="$t('close').toString()"
            />
          </button>
          <div class="image-zoom image-zoom__dialog-content">
            <video
              v-if="video"
              ref="videoElement"
              class="image-zoom__video"
              muted="true"
              loop="true"
              playsinline="true"
              autoplay
              :src="video"
              @click="closeDialog()"
            ><source
              :src="video"
            ></video>
            <img
              v-for="image in imageDtos"
              ref="imageElements"
              :key="image.source"
              class="image-zoom__image"
              :src="image.source"
              :alt="image.altText"
              @click="closeDialog()"
            >

            <ul class="image-zoom__thumbnails">
              <li
                v-if="video"
                class="image-zoom__thumbnail-container"
              >
                <button 
                  autofocus
                  :aria-label="$t('goToVideo').toString()"
                  :title="$t('goToVideo').toString()"
                  @click="handleVideoThumbnailClick()"
                >
                  <video 
                    ref="videoThumbnail"
                    class="image-zoom__thumbnail"
                    preload="metadata"
                    alt=""
                  ><source
                    :src="video + FIRST_VIDEO_FRAME_PARAMETER"
                  ></video>
                </button>
              </li>
              <li
                v-for="(image, index) in imageDtos"
                :key="image.thumbnail"
                class="image-zoom__thumbnail-container"
              >
                <button
                  :autofocus="index == 0 && !video"
                  :aria-label="`${ $t('goToImage') } ${ index+1 }`"
                  :title="`${ $t('goToImage') } ${ index+1 }`"
                  @click="handleImageThumbnailClick(image)"
                >
                  <img 
                    ref="imageThumbnails"
                    class="image-zoom__thumbnail"
                    :src="image.thumbnail"
                    alt=""
                  >
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>
