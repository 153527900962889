<script lang="ts" setup>
import { onMounted, ref, defineProps, onBeforeUnmount } from "vue";
import ShoppingCart from "../../components/shoppingCart/shoppingCart.vue";
import {
  CheckoutStage,
  CheckoutStages,
} from "../../services/models/checkout.models";
import {
  PlaceholderAddressModel,
  Cart,
} from "../../utils/API/Cart/cartAPISchema";
import { getShoppingCart } from "../../utils/API/Cart/cartAPI";
import Checkout from "../../components/checkout/checkout.vue";
import Verification from "../../components/verification/verification.vue";
import { useCheckoutStateStore } from "../../components/checkout/stores/checkoutStateStore";
import { useShoppingCartStore } from "../../components/shoppingCart/stores/shoppingCartStore";

const props = defineProps<{
  ksps: string | null; // JSON Content from NewCheckoutIndex.cshtml
  oldCheckoutUrl: string | null;
  maxCartSize?: string;
  initialLineItemCount?: string; // Number of line items in the cart when the component is mounted
}>();

const checkoutStateStore = useCheckoutStateStore();
const shoppingCartStore = useShoppingCartStore();
const cart = ref<Cart>();
const customerInformation = ref<PlaceholderAddressModel>();

/**
 * Fetches the shopping cart data from the API.
 * Sets the `cart` value with the fetched data.
 * Handles any errors that occur during the API call.
 * Sets the `cartIsLoading` value to indicate the loading state.
 *
 * @returns {Promise<void>} A promise that resolves when the shopping cart data is fetched and set.
 */
const apiGetShoppingCart = async () => {
  checkoutStateStore.setIsLoading(true);

  try {
    const data = await getShoppingCart();
    shoppingCartStore.setCart(data);
  } catch (error) {
    console.error(error);
  }

  checkoutStateStore.setIsLoading(false);
};

onMounted(() => {
  apiGetShoppingCart();
});
</script>

<template>
  <div>
    <ShoppingCart
      v-if="checkoutStateStore.currentCheckoutStage === CheckoutStages.ShoppingCart"
      :ksps="props.ksps"
      :old-checkout-url="props.oldCheckoutUrl"
      :max-cart-size="props.maxCartSize"
      :initial-line-item-count="props.initialLineItemCount"
    />
    <Verification
      v-if="checkoutStateStore.currentCheckoutStage === CheckoutStages.Verification"
    ></Verification>
    <Checkout
      v-if="checkoutStateStore.currentCheckoutStage === CheckoutStages.Checkout"
      :customer-information="customerInformation"
      :cart="cart"
    />
  </div>
</template>
