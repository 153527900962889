import {
    getCookie,
    setCookie
} from '../../utils';

const categoryLayout = () => {
    const cookieName = 'catlayout';
    const dataLayout = document.querySelector('[data-layout]');

    const setLayout = layout => {
        const flex = document.querySelector('[data-layout-flexible]');
        if (flex) {
            flex.dataset.layout = layout;
        }
    };

    // Toogle number of columns in category view
    // If cooke present, remember last choice
    const state = getCookie(cookieName);
    if (state !== '') {
        document.querySelectorAll('._js-category-display').forEach(i => {
            if (i.value === state) {
                i.checked = true;
            }
        });

        setLayout(state);
    }
    // When click, change layout + set new cookie
    document.querySelectorAll('._js-category-display').forEach(trigger => {
        trigger.addEventListener('change', e => {
            setCookie(cookieName, e.target.value, '30');
            setLayout(e.target.value);
        });
    });

};

export const init = () => {
    categoryLayout();
};
