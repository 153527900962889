<script lang="ts" setup>
import Icon from "../icon/icon.vue";
import { ValidatedCartResultDto } from "../../utils/API/Payment/paymentAPISchema";
import { ref, watch } from "vue";
import { formatWithoutRounding } from "../../mixins/productPrice.mixin";
import { usePaymentInformationStore } from "./stores/paymentInformationStore";

const props = defineProps<{
  errorDetails?: ValidatedCartResultDto;
  visible?: boolean;
}>();

const paymentInformationStore = usePaymentInformationStore();
const dialog = ref<HTMLDialogElement | null>(null);

watch(
  () => paymentInformationStore.showCartContentChangedPaymentError,
  (value) => {
    if (value) {
      dialog.value?.showModal();
    } else {
      dialog.value?.close();
    }
  },
);

function openDialog() {
  if (dialog.value) {
    paymentInformationStore.SetShowCartContentChangedPaymentError(true);
  }
}

const closeDialog = () => {
  if (dialog.value) {
    paymentInformationStore.SetShowCartContentChangedPaymentError(false);
  }
};

/**
 * Retrieves the current URL of the window.
 * @returns {string} The current URL.
 */
const getHref = () => {
  return window.location.href;
};

/**
 * Returns the origin of the current window location.
 *
 * @returns {string} The origin of the current window location.
 */
const getOrigin = () => {
  return window.location.origin;
};
</script>

<template>
  <dialog ref="dialog" class="dialog">
    <div class="dialog__layout">
      <div class="dialog__header">
        <h2>Beklager!</h2>
        <button
          class="dialog__close btn btn--icon"
          :aria-label="$t('close').toString()"
          @click="closeDialog()"
        >
          <Icon type="close" />
        </button>
      </div>
      <div class="dialog__contents finalize-payment-error">
        <p
          v-if="
            paymentInformationStore.cartContentChangedPaymentContent
              ?.description
          "
          class="finalize-payment-error__description"
        >
          {{
            paymentInformationStore.cartContentChangedPaymentContent
              ?.description
          }}
        </p>
        <table class="finalize-payment-error__table">
          <caption class="sr-only">
            Produktinformasjon
          </caption>
          <thead class="finalize-payment-error__table-head">
            <tr class="finalize-payment-error__table-row">
              <th scope="col">Produkt</th>
              <th scope="col">Antall</th>
              <th scope="col">Nytt antall</th>
            </tr>
          </thead>
          <tbody class="finalize-payment-error__table-body">
            <tr
              v-for="cartline in paymentInformationStore
                .cartContentChangedPaymentContent?.cartLinesUpdated"
              :key="cartline.name"
              class="finalize-payment-error__table-row"
            >
              <td>{{ cartline.name }} {{ cartline.size }}</td>
              <td class="finalize-payment-error__amount--old">
                {{ cartline.oldQuantity }}
              </td>
              <td class="finalize-payment-error__amount--new">
                {{ cartline.newQuantity }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="finalize-payment-error__details">
          <div
            v-if="
              paymentInformationStore.cartContentChangedPaymentContent
                ?.itemCount
            "
          >
            Ny fraktkostnad:
            <span class="finalize-payment-error__price">
              {{
                formatWithoutRounding(
                  paymentInformationStore.cartContentChangedPaymentContent
                    ?.shippingTotal,
                )
              }}
            </span>
          </div>
          <div
            v-if="
              paymentInformationStore.cartContentChangedPaymentContent
                ?.itemCount
            "
          >
            Ny totalsum:
            <span class="finalize-payment-error__price">
              {{
                formatWithoutRounding(
                  paymentInformationStore.cartContentChangedPaymentContent
                    ?.cartTotal,
                )
              }}
            </span>
          </div>
        </div>
        <div class="finalize-payment-error__actions">
          <button
            v-if="
              paymentInformationStore.cartContentChangedPaymentContent
                ?.itemCount
            "
            class="btn btn--profile finalize-payment-error__action"
          >
            Fortsett til betaling
          </button>
          <a
            v-if="
              !paymentInformationStore.cartContentChangedPaymentContent
                ?.itemCount
            "
            :href="getOrigin()"
            class="btn btn--profile finalize-payment-error__action"
          >
            Gå til forsiden
          </a>
          <a :href="getHref()" class="btn finalize-payment-error__action"
            >Gå til handlekurven</a
          >
        </div>
      </div>
    </div>
  </dialog>
</template>
