import { get } from '../../helpers/http';

export default class checkStock {
    constructor(element: HTMLElement){
        const sizeInputs = element.querySelectorAll('input._js-check-stock') as NodeListOf<HTMLInputElement>;
        const sizeSelect = element.querySelector('select._js-check-stock') as HTMLSelectElement | null;
        const warningElement = document.querySelector('._js-checkstock-warning') as HTMLElement | null;
        
        const checkStockStatus = (count: string | undefined, size: string | null | undefined) => {
            const url = '/publicapi/Inventory/FewItemsLeft';
            get(`${url}?inventoryCount=${count}&`)
                .then(res => res.json())
                .then(res => {
                    if (warningElement) {
                        warningElement.style.display = res ? 'block' : 'none';
                        warningElement.innerHTML = `${res ? `Få på lager i ${size}` : ''} `;
                    }
                });
        };

        // Call checkStockStatus for the initially selected option
        const selectedElement = sizeSelect?.options[sizeSelect.selectedIndex];
        if (selectedElement) {
            checkStockStatus(selectedElement.dataset.inventory, selectedElement.text);
        }
        
        if (sizeInputs && sizeInputs.length > 0) {
            sizeInputs.forEach(input => {
                input.addEventListener('click', () => {
                    checkStockStatus(input.dataset.inventory, input.nextElementSibling?.textContent);
                });
            });
        }
    
        if (sizeSelect) {
            sizeSelect.addEventListener('change',  () => {
                const selectedElement = sizeSelect.options[sizeSelect.selectedIndex];
                if (selectedElement) {
                    checkStockStatus(selectedElement.dataset.inventory, selectedElement.text);
                }
            });
        }

    }
}