<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import autoAnimate from "@formkit/auto-animate";
import { DeliveryWindow } from "../../utils/API/Delivery/deliveryAPISchema";
import {
  deliveryWindowTimeStamp,
  formatDay,
} from "../../../../helpers/checkoutHelpers";
import { useDeliveryInformationStore } from "./stores/deliveryInformationStore";

const props = defineProps<{
  deliveryWindows: DeliveryWindow[];
  selectedDeliveryWindow: DeliveryWindow;
}>();

const deliveryInformationStore = useDeliveryInformationStore();

const INITIAL_AMOUNT_VISIBLE = 4;
const containerElement = ref();
const visibleWindowsAmount = ref(INITIAL_AMOUNT_VISIBLE);

const remainingHiddenWindows = computed(() => {
  return props.deliveryWindows.length > visibleWindowsAmount.value;
});

const visibleWindows = computed(() => {
  return props.deliveryWindows.slice(0, visibleWindowsAmount.value);
});

const handleLoadMoreClick = (e: Event) => {
  e.preventDefault();
  visibleWindowsAmount.value += INITIAL_AMOUNT_VISIBLE;
};

const handleDeliveryWindowClick = (deliveryWindow: DeliveryWindow) => {
  deliveryInformationStore.setSelectedDeliveryWindow(deliveryWindow);
};

onMounted(() => {
  autoAnimate(containerElement.value);
});
</script>

<template>
  <div>
    <div ref="containerElement" class="checkout-delivery__windows">
      <label
        v-for="deliveryWindow in visibleWindows"
        :key="deliveryWindow.token"
        class="checkout-delivery__window"
        :for="deliveryWindow.token"
      >
        <input
          :id="deliveryWindow.token"
          type="radio"
          name="delivery-window"
          :checked="props.selectedDeliveryWindow === deliveryWindow"
          :value="deliveryWindow.token"
          @click="handleDeliveryWindowClick(deliveryWindow)"
        />
        <div class="checkout-delivery__window-content">
          <div class="checkout-delivery__window-date">
            {{ formatDay(new Date(deliveryWindow.end)) }}
          </div>
          <div class="checkout-delivery__window-description">
            <div class="checkout-delivery__window-timestamp">
              {{ deliveryWindowTimeStamp(new Date(deliveryWindow.start)) }}-{{
                deliveryWindowTimeStamp(new Date(deliveryWindow.end))
              }}
            </div>
          </div>
        </div>
      </label>
    </div>
    <button
      v-if="remainingHiddenWindows"
      class="btn btn--link checkout-delivery__windows-loadmore"
      @click="(e) => handleLoadMoreClick(e)"
    >
      Se flere tider
    </button>
  </div>
</template>
