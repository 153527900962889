﻿//Global functions only accessed by razor views.
import { postFormData } from '../../helpers/http';
import Mount from '../../helpers/mount';
import DeleteProfile from '../../global/modules/mypage_delete_profile';
import ResetPassword from '../../global/modules/mypage_reset_password';
import ChangeLoyaltyEmail from '../../global/modules/mypage_change_loyalty_email';
import EditProfile from '../../global/modules/mypage_edit_profile';
//TODO: Rewrite to class, rewrite to eventlisteners, remove from global object, remove Ajax form on mypage
export const myPageFunctions = {

    // Loyalty club consents
    updateconsentsBegin: () => {
        document.querySelector('._js-consents').setAttribute('disabled', 'disabled');
        const consentSave = document.querySelector('._js-btn-consents-save');
        consentSave.classList.add('is-animating');
        consentSave.setAttribute('disabled', 'disabled');
    },
    updateconsentsSuccess: () => {
        const consentSave = document.querySelector('._js-btn-consents-save');
        consentSave.classList.remove('is-animating');
        consentSave.removeAttribute('disabled');
    },
    updateconsentsComplete: () => {
        const saveStatus = document.querySelector('._js-consents-save-status');
        saveStatus.style.display = 'block';
        setTimeout(() => {
            saveStatus.style.display = 'none';
        }, 4000);
        handleConsentForm();
    },
    navToLoyalty: () => {
        handleConsentForm();
    }
};
const handleConsentForm = () => {
    //Get all required fields
    const emailConsent = document.getElementById('EmailConsent');
    const smsConsent = document.getElementById('SmsConsent');
    if (!emailConsent || !smsConsent) return;
    emailConsent.addEventListener('input', (e) => {
        checkSelections(e.target, smsConsent);
    });
    smsConsent.addEventListener('input', (e) => {
        checkSelections(e.target, emailConsent);
    });
    checkSelections(emailConsent, smsConsent);
};
const checkSelections = (node1, node2) => {
    //If one is selected, disable required on other field.
    //If none is selected, make both required
    node1.checked ? node2.removeAttribute('required') : node2.setAttribute('required', '');
    node2.checked ? node1.removeAttribute('required') : node1.setAttribute('required', '');
};
export default class Mypage {
    saveButton = document.querySelector('._js-btn-mypage-save');
    status = document.querySelector('._js-mypage-save-status');
    profileField = document.querySelector('._js-mypage-profile');
    constructor() {
        this.initMutationObserver();
        handleConsentForm();
    }
    /**
  * Custom init for modules. Listener required because the viewModel is occasionally rerendered without a page reload through ajax actionlinks.
  */
    initMutationObserver = () => {
        const ajaxContent = document.getElementById('ajax-content');
        if (!ajaxContent) return;
        const observer = new MutationObserver((mutationList, observer) => {
            mutationList.forEach((mutation) => {
                if (mutation.type == 'childList') {
                    Mount('_js-mypage-delete-profile', DeleteProfile);
                    Mount('_js-mypage-reset-password', ResetPassword);
                    Mount('_js-change-loyalty-club-email', ChangeLoyaltyEmail);
                    Mount('_js-mypage-profile', EditProfile);
                }
            });
        });
        const config = { childList: true, subtree: false };
        observer.observe(ajaxContent, config);
    };
}