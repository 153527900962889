import {
    getCookie,
    setCookie
} from '../../utils';

export default class Chat {
    constructor(element){

        const chatActive = getCookie('chatOpen') === 'opened';
    
        const load = id => {
            const script = document.createElement('script');
            const scriptURL = 'https://static.zdassets.com/ekr/snippet.js?key=' + id;
            script.type = 'text/javascript';
            script.id = 'ze-snippet';
            script.async = false;
            script.src = scriptURL;
            document.getElementsByTagName('head')[0].appendChild(script);
            element.classList.add('is-active');
            window.zESettings = {
                webWidget: {
                    launcher: {
                        chatLabel: {
                            '*': 'Chat'
                        },
                        label: {
                            '*': 'Åpne'
                        }
                    }
                }
            };
            setTimeout(() => {
                window.zE('webWidget', 'setLocale', 'no');
            },3000);
        };
    
        if (chatActive) {
            load(element.dataset.zenid);
            element.classList.add('is-active');
        }
    
        element.addEventListener('click', () => {
            setCookie('chatOpen', 'opened', '1');
            load(element.dataset.zenid);
        });
        
    }
}
