import { z } from "zod";
/*
 * Schema for Google Analytics namespace Voice.Internet.Models.GoogleAnalytics
 */

export type Promotion = z.infer<typeof PromotionSchema>;
export const PromotionSchema = z.object({
    creative: z.string().nullable(),
    position: z.string().nullable(),
});

export type Impression = z.infer<typeof ImpressionSchema>;

export const ImpressionSchema = z.object({
    brand: z.string().nullable(),
    category: z.string().nullable(),
    dimension2: z.string().nullable(), // Discount in percent
    dimension3: z.string().nullable(), // Product rating
    dimension4: z.string().nullable(), // På Lager/Delvis på Lager/Utsolgt
    dimension5: z.string().nullable(), // size and amount
    list: z.string().nullable(),
    position: z.string().nullable(),
    price: z.string().nullable(),
});

export type FieldBase = z.infer<typeof FieldBaseSchema>;
export const FieldBaseSchema = z.object({
    customDimensions: z.record(z.string()).nullable(),
    id: z.string().nullable(),
    name: z.string().nullable(),
});

export type Action = z.infer<typeof ActionSchema>;
export const ActionSchema = z.object({
    affiliation: z.string().nullable(),
    coupon: z.string().nullable(),
    id: z.string().nullable(),
    list: z.string().nullable(),
    option: z.string().nullable(),
    revenue: z.number().nullable(),
    shipping: z.number().nullable(),
    step: z.number().nullable(),
    tax: z.number().nullable(),
});

export type Product = z.infer<typeof ProductSchema>;
export const ProductSchema = z.object({
    id: z.string().nullable(),
    name: z.string().nullable(),
    brand: z.string().nullable(),
    category: z.string().nullable(),
    coupon: z.string().nullable(),
    dimension1: z.string().nullable(), // size
    dimension2: z.string().nullable(), // discount in percent
    dimension3: z.string().nullable(), // product rating
    dimension4: z.string().nullable(), // product rating
    metric6: z.string().nullable(), // discount
    position: z.number().nullable(),
    price: z.number().nullable(),
    quantity: z.number().nullable(),
    variant: z.string().nullable(),
    list: z.string().nullable(),
});

export type PromotionActionContainer = z.infer<
    typeof PromotionActionContainerSchema
>;
export const PromotionActionContainerSchema = z.object({
    promotions: z.array(PromotionSchema).nullable(),
});

export type ActionContainer = z.infer<typeof ActionContainerSchema>;
export const ActionContainerSchema = z.object({
    actionField: ActionSchema.nullable(),
});

export type ProductActionContainer = z.infer<
    typeof ProductActionContainerSchema
>;
export const ProductActionContainerSchema = z.object({
    actionField: ActionSchema.nullable(),
    impressions: z.array(ImpressionSchema).nullable(),
    products: z.array(ProductSchema).nullable(),
});

export type EcommerceContainer = z.infer<typeof EcommerceContainerSchema>;
export const EcommerceContainerSchema = z.object({
    add: ProductActionContainerSchema.nullable(),
    checkout: ProductActionContainerSchema.nullable(),
    checkout_option: ActionContainerSchema.nullable(),
    click: ProductActionContainerSchema.nullable(),
    currencyCode: z.string().nullable(),
    detail: ProductActionContainerSchema.nullable(),
    impressions: z.array(ImpressionSchema).nullable(),
    promoClick: PromotionActionContainerSchema.nullable(),
    promoView: PromotionActionContainerSchema.nullable(),
    purchase: ProductActionContainerSchema.nullable(),
    refund: ProductActionContainerSchema.nullable(),
    remove: ProductActionContainerSchema.nullable(),
});

export type EcommerceDataLayer = z.infer<typeof EcommerceDataLayerSchema>;
export const EcommerceDataLayerSchema = z.object({
    couponValue: z.number().nullable(),
    ecommerce: EcommerceContainerSchema.nullable(),
    ecommPageType: z.string().nullable(),
    ecommProdId: z.string().nullable(),
    ecommTotalValue: z.string().nullable(),
    event: z.string().nullable(),
    eventCallback: z.string().nullable(),
    eventTimeout: z.number().nullable(),
    newsletterSignUp: z.string().nullable(),
    orderId: z.string().nullable(),
    paymentMethod: z.string().nullable(),
    shippingMethod: z.string().nullable(),
    userId: z.string().nullable(),
    userRegistered: z.string().nullable(),
});
