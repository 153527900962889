export type CheckoutStage =
    (typeof CheckoutStages)[keyof typeof CheckoutStages];
export const CheckoutStages = {
    ShoppingCart: "Handlekurv",
    Verification: "Verifisering",
    Checkout: "Kasse",
} as const;

export type CheckoutStep = (typeof CheckoutSteps)[keyof typeof CheckoutSteps];
export const CheckoutSteps = {
    CustomerInformation: "Kundeinformasjon",
    Delivery: "Levering",
    Payment: "Betaling",
    FinalizeOrder: "Fullfør bestilling",
} as const;

export type VerificationStep =
    (typeof VerificationSteps)[keyof typeof VerificationSteps];
export const VerificationSteps = {
    DecideVerificationType: "Hvordan ønsker du å fortsette?",
    Login: "Logg inn",
} as const;

export type ShippingMethod =
    (typeof ShippingMethods)[keyof typeof ShippingMethods];
export const ShippingMethods = {
    Porterbuddy: "Porterbuddy",
    HomeDelivery: "HomeDelivery",
    PickUpInStore: "PickUpInStore",
    MyPack: "MyPack",
    Retail: "RETAIL",
} as const;
